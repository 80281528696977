import {CURRENT_PROFILE, SET_PROFILE} from './profile.types'

const initialState = {
    currentProfile: 'Personal'
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CURRENT_PROFILE:
      return state
    case SET_PROFILE:
      return {...state, currentProfile: action.payload}
    default: return state
  }
}

export default reducer
