import React from 'react'
import { Field, ErrorMessage } from 'formik'
import TextError from './TextError'

function Textarea (props) {
  const { label, name, ...rest } = props
  return (
    <div className="form-group row">
      <label className="col-lg-3 font-weight-bold text-dark col-form-label form-control-label text-2 required" htmlFor={name}>{label}</label>
      <div className="col-lg-9">
        <Field className='form-control' as='textarea' id={name} name={name} {...rest} />
        <ErrorMessage component={TextError} name={name} />
      </div>
    </div>
  )
}

export default Textarea
