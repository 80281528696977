import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Link } from "react-router-dom";

const Gallery = () => {
  return (
    <div className="body">
      <Header />

      <div role="main" className="main">
        <section className="page-header page-header-classic">
          <div className="container">
            <div className="row">
              <div className="col">
                <ul className="breadcrumb">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li className="active">Pages</li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col p-static">
                <h1 data-title-border>Executive Members</h1>
              </div>
            </div>
          </div>
        </section>

        <div className="container py-4">
          <ul
            className="nav nav-pills sort-source sort-source-style-3 justify-content-center"
            data-sort-id="team"
            data-option-key="filter"
          >
            <li className="nav-item active" data-option-value="*">
              <a
                className="nav-link text-1 text-uppercase active"
                href="!#"
                rel="noopener noreferrer"
              >
                Show All
              </a>
            </li>
          </ul>
          <div className="sort-destination-loader sort-destination-loader-showing mt-4 pt-2">
            <div className="row team-list sort-destination" data-sort-id="team">
              <div className="col-12 col-sm-6 col-lg-3 isotope-item leadership">
                <span className="thumb-info thumb-info-hide-wrapper-bg mb-4">
                  <span className="thumb-info-wrapper">
                    <img
                      src="img/wc/nithin_chitari.jpg"
                      className="img-fluid"
                      alt=""
                    />
                    <span className="thumb-info-title d-none">
                      <span className="thumb-info-inner">Nitin Chitari</span>
                      <span className="thumb-info-type">Member</span>
                    </span>
                  </span>
                </span>
              </div>

              <div className="col-12 col-sm-6 col-lg-3 isotope-item leadership">
                <span className="thumb-info thumb-info-hide-wrapper-bg mb-4">
                  <span className="thumb-info-wrapper">
                    <img
                      src="img/wc/balchandara_bandodkar.jpg"
                      className="img-fluid"
                      alt=""
                    />
                    <span className="thumb-info-title">
                      <span className="thumb-info-inner">
                        Dr Balachandra Bandodkar
                      </span>
                      <span className="thumb-info-type">Member</span>
                    </span>
                  </span>
                </span>
              </div>

              <div className="col-12 col-sm-6 col-lg-3 isotope-item leadership">
                <span className="thumb-info thumb-info-hide-wrapper-bg mb-4">
                  <span className="thumb-info-wrapper">
                    <img
                      src="img/wc/AvinashShetty.jpg"
                      className="img-fluid"
                      alt=""
                    />
                    <span className="thumb-info-title">
                      <span className="thumb-info-inner">Avinash Shetty</span>
                      <span className="thumb-info-type">Member</span>
                    </span>
                  </span>
                </span>
              </div>

              <div className="col-12 col-sm-6 col-lg-3 isotope-item leadership">
                <span className="thumb-info thumb-info-hide-wrapper-bg mb-4">
                  <span className="thumb-info-wrapper">
                    <img
                      src="img/wc/gururaj_m_j.jpg"
                      className="img-fluid"
                      alt=""
                    />
                    <span className="thumb-info-title">
                      <span className="thumb-info-inner">Gururaj M J </span>
                      <span className="thumb-info-type">Member</span>
                    </span>
                  </span>
                </span>
              </div>

              <div className="col-12 col-sm-6 col-lg-3 isotope-item leadership">
                <span className="thumb-info thumb-info-hide-wrapper-bg mb-4">
                  <span className="thumb-info-wrapper">
                    <img src="img/wc/deepak.jpg" className="img-fluid" alt="" />
                    <span className="thumb-info-title">
                      <span className="thumb-info-inner">Deepak Sorab</span>
                      <span className="thumb-info-type">Member</span>
                    </span>
                  </span>
                </span>
              </div>

              <div className="col-12 col-sm-6 col-lg-3 isotope-item leadership">
                <span className="thumb-info thumb-info-hide-wrapper-bg mb-4">
                  <span className="thumb-info-wrapper">
                    <img
                      src="img/wc/dinesh_shet.jpg"
                      className="img-fluid"
                      alt=""
                    />
                    <span className="thumb-info-title">
                      <span className="thumb-info-inner">Dinesh Shet</span>
                      <span className="thumb-info-type">Member</span>
                    </span>
                  </span>
                </span>
              </div>

              <div className="col-12 col-sm-6 col-lg-3 isotope-item leadership">
                <span className="thumb-info thumb-info-hide-wrapper-bg mb-4">
                  <span className="thumb-info-wrapper">
                    <img
                      src="img/wc/divya_vinay.jpg"
                      className="img-fluid"
                      alt=""
                    />
                    <span className="thumb-info-title">
                      <span className="thumb-info-inner">Divya Vinay</span>
                      <span className="thumb-info-type">Member</span>
                    </span>
                  </span>
                </span>
              </div>

              <div className="col-12 col-sm-6 col-lg-3 isotope-item leadership">
                <span className="thumb-info thumb-info-hide-wrapper-bg mb-4">
                  <span className="thumb-info-wrapper">
                    <img src="img/wc/rajat.jpg" className="img-fluid" alt="" />
                    <span className="thumb-info-title">
                      <span className="thumb-info-inner">Rajat Gudigar</span>
                      <span className="thumb-info-type">Member</span>
                    </span>
                  </span>
                </span>
              </div>

              <div className="col-12 col-sm-6 col-lg-3 isotope-item leadership">
                <span className="thumb-info thumb-info-hide-wrapper-bg mb-4">
                  <span className="thumb-info-wrapper">
                    <img src="img/wc/ganesh.jpg" className="img-fluid" alt="" />
                    <span className="thumb-info-title">
                      <span className="thumb-info-inner">Ganesh Gudigar</span>
                      <span className="thumb-info-type">Member</span>
                    </span>
                  </span>
                </span>
              </div>

              <div className="col-12 col-sm-6 col-lg-3 isotope-item leadership">
                <span className="thumb-info thumb-info-hide-wrapper-bg mb-4">
                  <span className="thumb-info-wrapper">
                    <img src="img/wc/harish.jpg" className="img-fluid" alt="" />
                    <span className="thumb-info-title">
                      <span className="thumb-info-inner">Harish Bilgi</span>
                      <span className="thumb-info-type">Member</span>
                    </span>
                  </span>
                </span>
              </div>

              <div className="col-12 col-sm-6 col-lg-3 isotope-item leadership">
                <span className="thumb-info thumb-info-hide-wrapper-bg mb-4">
                  <span className="thumb-info-wrapper">
                    <img
                      src="img/wc/jagadeesh_biligi.jpg"
                      className="img-fluid"
                      alt=""
                    />
                    <span className="thumb-info-title">
                      <span className="thumb-info-inner">Jagdeesh Bilgi</span>
                      <span className="thumb-info-type">Member</span>
                    </span>
                  </span>
                </span>
              </div>

              <div className="col-12 col-sm-6 col-lg-3 isotope-item leadership">
                <span className="thumb-info thumb-info-hide-wrapper-bg mb-4">
                  <span className="thumb-info-wrapper">
                    <img
                      src="img/wc/kashama.jpg"
                      className="img-fluid"
                      alt=""
                    />
                    <span className="thumb-info-title">
                      <span className="thumb-info-inner">
                        kshama harsh gudikar
                      </span>
                      <span className="thumb-info-type">Member</span>
                    </span>
                  </span>
                </span>
              </div>

              <div className="col-12 col-sm-6 col-lg-3 isotope-item leadership">
                <span className="thumb-info thumb-info-hide-wrapper-bg mb-4">
                  <span className="thumb-info-wrapper">
                    <img
                      src="img/wc/manohar_m_g.jpg"
                      className="img-fluid"
                      alt=""
                    />
                    <span className="thumb-info-title">
                      <span className="thumb-info-inner">Manohar M G</span>
                      <span className="thumb-info-type">Member</span>
                    </span>
                  </span>
                </span>
              </div>

              <div className="col-12 col-sm-6 col-lg-3 isotope-item leadership">
                <span className="thumb-info thumb-info-hide-wrapper-bg mb-4">
                  <span className="thumb-info-wrapper">
                    <img
                      src="img/wc/mithun_a_gudigar.jpg"
                      className="img-fluid"
                      alt=""
                    />
                    <span className="thumb-info-title">
                      <span className="thumb-info-inner">Mithun A Gudigar</span>
                      <span className="thumb-info-type">Member</span>
                    </span>
                  </span>
                </span>
              </div>

              <div className="col-12 col-sm-6 col-lg-3 isotope-item leadership">
                <span className="thumb-info thumb-info-hide-wrapper-bg mb-4">
                  <span className="thumb-info-wrapper">
                    <img
                      src="img/wc/harsha_gudaigar.jpg"
                      className="img-fluid"
                      alt=""
                    />
                    <span className="thumb-info-title">
                      <span className="thumb-info-inner">Harsha Gudigar</span>
                      <span className="thumb-info-type">Member</span>
                    </span>
                  </span>
                </span>
              </div>

              <div className="col-12 col-sm-6 col-lg-3 isotope-item leadership">
                <span className="thumb-info thumb-info-hide-wrapper-bg mb-4">
                  <span className="thumb-info-wrapper">
                    <img
                      src="img/wc/poornachandra.jpg"
                      className="img-fluid"
                      alt=""
                    />
                    <span className="thumb-info-title">
                      <span className="thumb-info-inner">
                        Poornachandra Kashi
                      </span>
                      <span className="thumb-info-type">Member</span>
                    </span>
                  </span>
                </span>
              </div>

              <div className="col-12 col-sm-6 col-lg-3 isotope-item leadership">
                <span className="thumb-info thumb-info-hide-wrapper-bg mb-4">
                  <span className="thumb-info-wrapper">
                    <img
                      src="img/wc/vivekananda_maroor.jpg"
                      className="img-fluid"
                      alt=""
                    />
                    <span className="thumb-info-title">
                      <span className="thumb-info-inner">
                        Vivekananda Maroor
                      </span>
                      <span className="thumb-info-type">Member</span>
                    </span>
                  </span>
                </span>
              </div>

              <div className="col-12 col-sm-6 col-lg-3 isotope-item leadership">
                <span className="thumb-info thumb-info-hide-wrapper-bg mb-4">
                  <span className="thumb-info-wrapper">
                    <img
                      src="img/wc/sujith_honnavar.jpg"
                      className="img-fluid"
                      alt=""
                    />
                    <span className="thumb-info-title">
                      <span className="thumb-info-inner">Sujith Honnavar</span>
                      <span className="thumb-info-type">Member</span>
                    </span>
                  </span>
                </span>
              </div>

              <div className="col-12 col-sm-6 col-lg-3 isotope-item leadership">
                <span className="thumb-info thumb-info-hide-wrapper-bg mb-4">
                  <span className="thumb-info-wrapper">
                    <img src="img/wc/pramod.jpg" className="img-fluid" alt="" />
                    <span className="thumb-info-title">
                      <span className="thumb-info-inner">
                        Pramod Krishnappa{" "}
                      </span>
                      <span className="thumb-info-type">Member</span>
                    </span>
                  </span>
                </span>
              </div>

              <div className="col-12 col-sm-6 col-lg-3 isotope-item leadership">
                <span className="thumb-info thumb-info-hide-wrapper-bg mb-4">
                  <span className="thumb-info-wrapper">
                    <img
                      src="img/wc/vijay_chitrak.jpg"
                      className="img-fluid"
                      alt=""
                    />
                    <span className="thumb-info-title">
                      <span className="thumb-info-inner">Vijay Chitrakar</span>
                      <span className="thumb-info-type">Member</span>
                    </span>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Gallery;
