import React, {useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import {postProfile} from '../../../actions/profile'
import { Formik, Form } from 'formik'
import FormikControl from '../../formik/FormikControl'
import * as Yup from 'yup'
import './profile.scss'

export default function Identity(props) {
  const dispatch = useDispatch()
  const user = useSelector(state => state.auth.user);
  const [isEdit, setIsEdit] = useState(false);

  let profileObject = require('./profileObject.json')
  const profile = user && user.profile
  let keylist = Object.keys(profileObject)
  keylist.map((key)=>{
    if (profile[key]) 
      return profileObject[key] = profile[key]
    return null
  })
  const initialValues = profileObject.identity

  const validationSchema = Yup.object({
    pan: Yup.string(),
    voterID: Yup.string(),
    aadharCard: Yup.string(),
    CitizenOf: Yup.string()
  })

  const onSubmit = (values, submitProps) => {
    // console.log('Form data', JSON.parse(JSON.stringify(values)))
    let formData = {...profileObject, identity: values}
    let newUser = user
    newUser.profile = formData
    postProfile(dispatch, newUser, user._id)
    setIsEdit(false)
  }

  return (
    <>
      <div className="col-lg-9">
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} >
          {formik => {
              return (
                  <Form>
                    <div className="fieldset">
                      <fieldset>
                      <legend>Identity</legend>
                    <FormikControl control='input' type='text' label='PAN Card Number' name='pan' disabled={!isEdit}/>
                    <FormikControl control='input' type='text' label='Voter ID Number' name='voterID' disabled={!isEdit}/>
                    <FormikControl control='input' type='text' label='Aadhar Card Number' name='aadharCard' disabled={!isEdit}/>
                    <FormikControl control='input' type='text' label='Citizen of' name='CitizenOf' disabled={!isEdit}/>
                    {isEdit && <button className="btn btn-primary" type='submit' disabled={!formik.isValid}>Submit</button>}
                    {!isEdit && <button className="btn btn-primary" type="button" onClick={()=>setIsEdit(true)}>Edit</button>}
                    </fieldset>
                    </div>
                  </Form>
              )
          }}
        </Formik>
      </div>
    </>
  )
}


