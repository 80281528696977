import React, {useState} from 'react'

export default function ShowHistoryPages(props) {
    const [page, setPage] = useState(1);
    const pages = props.pages
    const pagecount = Object.keys(pages[0]).length; 
    return (
            <>
            <div className="container">
                <ul className="pager">
                    <button 
                        className="btn btn-rounded btn-primary mb-2"
                        disabled={(page===1)?true:false}
                        onClick={()=>setPage((page>1)?page-1:page)}>
                        Previous
                    </button>
                    <button 
                        className="btn btn-rounded btn-primary mb-2" 
                        disabled={(page===pagecount)?true:false}
                        onClick={()=>setPage((page<pagecount)?page+1:pagecount)}>
                        Next
                    </button>
                </ul>
            </div>
            <div className="container doc">
                {pages[0][page]}
            </div>
            </>
    )
}
