import axios from "../axios";
import { setAlert } from "./alert";
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  CLEAR_PROFILE,
} from "./types";

import setAuthToken from "../utils/setAuthToken";

//Load user
export const loadUser = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  await axios.get("/api/auth")
    .then((response)=>{
      dispatch({type: USER_LOADED, payload: response.data})
    })
    .catch ((error) => {
      dispatch({type: AUTH_ERROR})
    })
};

//Register
export const register = ({ username, email, password }) => async (dispatch) => {
  const newUser = {
    username,
    email,
    password,
  };

  const config = {
    header: {
      "Content-Type": "Application/json",
    },
  };

  try {
    const res = await axios.post("/api/users/register", newUser, config);
    dispatch(setAlert("Registration Successful!", "success", 7000));
    dispatch({
      type: REGISTER_SUCCESS,
      payload: res.data,
    });

    // dispatch(loadUser());
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger", 7000)));
    }

    dispatch({
      type: REGISTER_FAIL,
    });
  }
};

//Login
export const login = (email, password) => async (dispatch) => {
  const newUser = {email,password,};
  const config = {header: {"Content-Type": "Application/json",},};
  console.log("inside auth login ")
  try {
    const res = await axios.post("/api/users/login", newUser, config);

    dispatch({type: LOGIN_SUCCESS,payload: res.data,});
    dispatch(loadUser());
  } 
  catch (err) {
    const errors = err.response.data.errors;
    if (errors) {errors.forEach((error) => dispatch(setAlert(error.msg, "danger", 7000)));}
    dispatch({type: LOGIN_FAIL,});
  }
};

//Logout /Clear Profile
export const logout = () => async (dispatch) => {
  dispatch({
    type: CLEAR_PROFILE,
  });
  dispatch({
    type: LOGOUT,
  });
};
