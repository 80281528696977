import React, { useState, Redirect } from "react";
import { connect } from "react-redux";
import SectionBlock from './SectionBlock'
import { setAlert } from "../../src/actions/alert";
import { register } from "../../src/actions/auth";
import PropTypes from "prop-types";
import Alert from "../components/Layouts/Alert";

import { Link } from "react-router-dom";
const Register = ({ setAlert, register, isAuthenticated }) => {
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    password2: "",
  });

  const onChange = (e) =>
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

  const { username, email, password, password2 } = formData;

  const onSubmit = async (e) => {
    e.preventDefault();

    if (password !== password2) {
      setAlert("Passwords do not match", "danger", 3000);
    } else {
      register({ username, email, password });
    }
  };

  //Redirect if logged in
  if (isAuthenticated) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <div className="container">
      <SectionBlock name="Please Register"/>
      <div className="row">
        <div className="col-lg-6">
          <br />

          <center>
            <img src="img/gudigars.png" alt="" className="registerPageLogo" />
            <br />
            Already registered ? Please login <Link to="/login"> here </Link>.
          </center>
        </div>

        <div className="col-lg-5">
          <div className="featured-box featured-box-primary text-left mt-5">
            <div className="box-content">
              <h4 className="color-primary font-weight-semibold text-4 text-uppercase mb-3">
                Register An Account
              </h4>

              <Alert />

              <form
                action="/"
                id="frmSignUp"
                method="post"
                className="needs-validation"
                onSubmit={(e) => onSubmit(e)}
              >
                <div className="form-row">
                  <div className="form-group col">
                    <label className="font-weight-bold text-dark text-2">
                      Username (Display Name)
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="username"
                      placeholder="Enter your Username"
                      value={username}
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col">
                    <label className="font-weight-bold text-dark text-2">
                      E-mail ID
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="email"
                      placeholder="Enter your Email ID"
                      value={email}
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-lg-6">
                    <label className="font-weight-bold text-dark text-2">
                      Password
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      name="password"
                      placeholder="Enter your Password"
                      value={password}
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                  <div className="form-group col-lg-6">
                    <label className="font-weight-bold text-dark text-2">
                      Re-enter Password
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Confirm your password"
                      name="password2"
                      value={password2}
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-lg-9">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="terms"
                        required
                      />
                      <label
                        className="custom-control-label text-2"
                        htmlFor="terms"
                      >
                        I have read and agree to the{" "}
                        <Link to="/terms">terms of service</Link>
                      </label>
                    </div>
                  </div>
                  <div className="form-group col-lg-3">
                    <input
                      type="submit"
                      defaultValue="Register"
                      className="btn btn-primary btn-sm btn-modern float-right"
                      data-loading-text="Loading..."
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Register.propTypes = {
  setAlert: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { setAlert, register })(Register);
