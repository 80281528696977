import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Link } from "react-router-dom";

const Gallery = () => {
  return (
    <div className="body">
      <Header />

      <div role="main" className="main">
        <section className="page-header page-header-classic">
          <div className="container">
            <div className="row">
              <div className="col">
                <ul className="breadcrumb">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li className="active">Pages</li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col p-static">
                <h1 data-title-border>Gallery</h1>
              </div>
            </div>
          </div>
        </section>

        <div className="container py-2">
          <div className="row">
            <div className="col-lg-3">
              <aside
                className="sidebar"
                id="sidebar"
                data-plugin-sticky
                data-plugin-options="{'minWidth': 991, 'containerSelector': '.container', 'padding': {'top': 110}}"
              >
                <h5 className="font-weight-bold">
                  <strong>Filter</strong> By
                </h5>
                <ul
                  className="nav nav-list flex-column sort-source mb-5"
                  data-sort-id="portfolio"
                  data-option-key="filter"
                  data-plugin-options="{'layoutMode': 'fitRows', 'filter': '*'}"
                >
                  <li className="nav-item" data-option-value="*">
                    <a className="nav-link active" href="!#">
                      Show All
                    </a>
                  </li>
                  <li className="nav-item" data-option-value=".websites">
                    <a className="nav-link" href="!#">
                      Websites
                    </a>
                  </li>
                  <li className="nav-item" data-option-value=".logos">
                    <a className="nav-link" href="!#">
                      Logos
                    </a>
                  </li>
                  <li className="nav-item" data-option-value=".brands">
                    <a className="nav-link" href="!#">
                      Brands
                    </a>
                  </li>
                  <li className="nav-item" data-option-value=".medias">
                    <a className="nav-link" href="!#">
                      Medias
                    </a>
                  </li>
                </ul>
              </aside>
            </div>
            <div className="col-lg-9">
              <div className="sort-destination-loader sort-destination-loader-showing">
                <div
                  className="row portfolio-list sort-destination"
                  data-sort-id="portfolio"
                >
                  <div className="col-md-6 col-lg-4 isotope-item brands">
                    <div className="portfolio-item">
                      <a href="portfolio-single-wide-slider.html">
                        <span className="thumb-info thumb-info-lighten border-radius-0">
                          <span className="thumb-info-wrapper border-radius-0">
                            <img
                              src="img/projects/project.jpg"
                              className="img-fluid border-radius-0"
                              alt=""
                            />
                            <span className="thumb-info-title">
                              <span className="thumb-info-inner">
                                Presentation
                              </span>
                              <span className="thumb-info-type">Brand</span>
                            </span>
                            <span className="thumb-info-action">
                              <span className="thumb-info-action-icon bg-dark opacity-8">
                                <i className="fas fa-plus" />
                              </span>
                            </span>
                          </span>
                        </span>
                      </a>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 isotope-item medias">
                    <div className="portfolio-item">
                      <a href="portfolio-single-wide-slider.html">
                        <span className="thumb-info thumb-info-lighten border-radius-0">
                          <span className="thumb-info-wrapper border-radius-0">
                            <span
                              className="owl-carousel owl-theme dots-inside m-0"
                              data-plugin-options="{'items': 1, 'margin': 20, 'animateOut': 'fadeOut', 'autoplay': true, 'autoplayTimeout': 3000}"
                            >
                              <span>
                                <img
                                  src="img/projects/project-1.jpg"
                                  className="img-fluid border-radius-0"
                                  alt=""
                                />
                              </span>
                              <span>
                                <img
                                  src="img/projects/project-1-2.jpg"
                                  className="img-fluid border-radius-0"
                                  alt=""
                                />
                              </span>
                            </span>
                            <span className="thumb-info-title">
                              <span className="thumb-info-inner">
                                Porto Watch
                              </span>
                              <span className="thumb-info-type">Media</span>
                            </span>
                            <span className="thumb-info-action">
                              <span className="thumb-info-action-icon bg-dark opacity-8">
                                <i className="fas fa-plus" />
                              </span>
                            </span>
                          </span>
                        </span>
                      </a>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 isotope-item logos">
                    <div className="portfolio-item">
                      <a href="portfolio-single-wide-slider.html">
                        <span className="thumb-info thumb-info-lighten border-radius-0">
                          <span className="thumb-info-wrapper border-radius-0">
                            <img
                              src="img/projects/project-2.jpg"
                              className="img-fluid border-radius-0"
                              alt=""
                            />
                            <span className="thumb-info-title">
                              <span className="thumb-info-inner">Identity</span>
                              <span className="thumb-info-type">Logo</span>
                            </span>
                            <span className="thumb-info-action">
                              <span className="thumb-info-action-icon bg-dark opacity-8">
                                <i className="fas fa-plus" />
                              </span>
                            </span>
                          </span>
                        </span>
                      </a>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 isotope-item websites">
                    <div className="portfolio-item">
                      <a href="portfolio-single-wide-slider.html">
                        <span className="thumb-info thumb-info-lighten border-radius-0">
                          <span className="thumb-info-wrapper border-radius-0">
                            <img
                              src="img/projects/project-27.jpg"
                              className="img-fluid border-radius-0"
                              alt=""
                            />
                            <span className="thumb-info-title">
                              <span className="thumb-info-inner">
                                Porto Screens
                              </span>
                              <span className="thumb-info-type">Website</span>
                            </span>
                            <span className="thumb-info-action">
                              <span className="thumb-info-action-icon bg-dark opacity-8">
                                <i className="fas fa-plus" />
                              </span>
                            </span>
                          </span>
                        </span>
                      </a>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 isotope-item logos">
                    <div className="portfolio-item">
                      <a href="portfolio-single-wide-slider.html">
                        <span className="thumb-info thumb-info-lighten border-radius-0">
                          <span className="thumb-info-wrapper border-radius-0">
                            <img
                              src="img/projects/project-4.jpg"
                              className="img-fluid border-radius-0"
                              alt=""
                            />
                            <span className="thumb-info-title">
                              <span className="thumb-info-inner">
                                Three Bottles
                              </span>
                              <span className="thumb-info-type">Logo</span>
                            </span>
                            <span className="thumb-info-action">
                              <span className="thumb-info-action-icon bg-dark opacity-8">
                                <i className="fas fa-plus" />
                              </span>
                            </span>
                          </span>
                        </span>
                      </a>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 isotope-item brands">
                    <div className="portfolio-item">
                      <a href="portfolio-single-wide-slider.html">
                        <span className="thumb-info thumb-info-lighten border-radius-0">
                          <span className="thumb-info-wrapper border-radius-0">
                            <img
                              src="img/projects/project-5.jpg"
                              className="img-fluid border-radius-0"
                              alt=""
                            />
                            <span className="thumb-info-title">
                              <span className="thumb-info-inner">
                                Company T-Shirt
                              </span>
                              <span className="thumb-info-type">Brand</span>
                            </span>
                            <span className="thumb-info-action">
                              <span className="thumb-info-action-icon bg-dark opacity-8">
                                <i className="fas fa-plus" />
                              </span>
                            </span>
                          </span>
                        </span>
                      </a>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 isotope-item websites">
                    <div className="portfolio-item">
                      <a href="portfolio-single-wide-slider.html">
                        <span className="thumb-info thumb-info-lighten border-radius-0">
                          <span className="thumb-info-wrapper border-radius-0">
                            <img
                              src="img/projects/project-6.jpg"
                              className="img-fluid border-radius-0"
                              alt=""
                            />
                            <span className="thumb-info-title">
                              <span className="thumb-info-inner">
                                Mobile Mockup
                              </span>
                              <span className="thumb-info-type">Website</span>
                            </span>
                            <span className="thumb-info-action">
                              <span className="thumb-info-action-icon bg-dark opacity-8">
                                <i className="fas fa-plus" />
                              </span>
                            </span>
                          </span>
                        </span>
                      </a>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 isotope-item medias">
                    <div className="portfolio-item">
                      <a href="portfolio-single-wide-slider.html">
                        <span className="thumb-info thumb-info-lighten border-radius-0">
                          <span className="thumb-info-wrapper border-radius-0">
                            <img
                              src="img/projects/project-7.jpg"
                              className="img-fluid border-radius-0"
                              alt=""
                            />
                            <span className="thumb-info-title">
                              <span className="thumb-info-inner">
                                Porto Label
                              </span>
                              <span className="thumb-info-type">Media</span>
                            </span>
                            <span className="thumb-info-action">
                              <span className="thumb-info-action-icon bg-dark opacity-8">
                                <i className="fas fa-plus" />
                              </span>
                            </span>
                          </span>
                        </span>
                      </a>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 isotope-item logos">
                    <div className="portfolio-item">
                      <a href="portfolio-single-wide-slider.html">
                        <span className="thumb-info thumb-info-lighten border-radius-0">
                          <span className="thumb-info-wrapper border-radius-0">
                            <img
                              src="img/projects/project-23.jpg"
                              className="img-fluid border-radius-0"
                              alt=""
                            />
                            <span className="thumb-info-title">
                              <span className="thumb-info-inner">
                                Business Folders
                              </span>
                              <span className="thumb-info-type">Logo</span>
                            </span>
                            <span className="thumb-info-action">
                              <span className="thumb-info-action-icon bg-dark opacity-8">
                                <i className="fas fa-plus" />
                              </span>
                            </span>
                          </span>
                        </span>
                      </a>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 isotope-item websites">
                    <div className="portfolio-item">
                      <a href="portfolio-single-wide-slider.html">
                        <span className="thumb-info thumb-info-lighten border-radius-0">
                          <span className="thumb-info-wrapper border-radius-0">
                            <img
                              src="img/projects/project-24.jpg"
                              className="img-fluid border-radius-0"
                              alt=""
                            />
                            <span className="thumb-info-title">
                              <span className="thumb-info-inner">
                                Tablet Screen
                              </span>
                              <span className="thumb-info-type">Website</span>
                            </span>
                            <span className="thumb-info-action">
                              <span className="thumb-info-action-icon bg-dark opacity-8">
                                <i className="fas fa-plus" />
                              </span>
                            </span>
                          </span>
                        </span>
                      </a>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 isotope-item medias">
                    <div className="portfolio-item">
                      <a href="portfolio-single-wide-slider.html">
                        <span className="thumb-info thumb-info-lighten border-radius-0">
                          <span className="thumb-info-wrapper border-radius-0">
                            <img
                              src="img/projects/project-25.jpg"
                              className="img-fluid border-radius-0"
                              alt=""
                            />
                            <span className="thumb-info-title">
                              <span className="thumb-info-inner">
                                Black Watch
                              </span>
                              <span className="thumb-info-type">Media</span>
                            </span>
                            <span className="thumb-info-action">
                              <span className="thumb-info-action-icon bg-dark opacity-8">
                                <i className="fas fa-plus" />
                              </span>
                            </span>
                          </span>
                        </span>
                      </a>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 isotope-item websites">
                    <div className="portfolio-item">
                      <a href="portfolio-single-wide-slider.html">
                        <span className="thumb-info thumb-info-lighten border-radius-0">
                          <span className="thumb-info-wrapper border-radius-0">
                            <img
                              src="img/projects/project-26.jpg"
                              className="img-fluid border-radius-0"
                              alt=""
                            />
                            <span className="thumb-info-title">
                              <span className="thumb-info-inner">
                                Monitor Mockup
                              </span>
                              <span className="thumb-info-type">Website</span>
                            </span>
                            <span className="thumb-info-action">
                              <span className="thumb-info-action-icon bg-dark opacity-8">
                                <i className="fas fa-plus" />
                              </span>
                            </span>
                          </span>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Gallery;
