import React, {useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import {postProfile} from '../../../actions/profile'
import { Formik, Form } from 'formik'
import FormikControl from '../../formik/FormikControl'
import * as Yup from 'yup'
import './profile.scss'

export default function Contach(props) {
  const dispatch = useDispatch()
  const user = useSelector(state => state.auth.user);
  const [isEdit, setIsEdit] = useState(false);

  let profileObject = require('./profileObject.json')
  
  const profile = user && user.profile
  let keylist = Object.keys(profileObject)
  keylist.map((key)=>{
    if (profile[key]) 
      return profileObject[key] = profile[key]
    return null
  })
  const initialValues = profileObject.contact

  const validationSchema = Yup.object({
    mobile:     Yup.string(),
    mobile1:    Yup.string(),
    mobile2:    Yup.string(),
    landline:   Yup.string(),
    whatsapp:   Yup.string(),
    linkedin:   Yup.string(),
    facebook:   Yup.string(),
    twitter:    Yup.string(),
  })

  const onSubmit = (values, submitProps) => {
    // console.log('Form data', JSON.parse(JSON.stringify(values)))
    let formData = {...profileObject, contact: values}
    let newUser = user
    newUser.profile = formData
    postProfile(dispatch, newUser, user._id)
    setIsEdit(false)
  }

  return (
    <>
      <div className="col-lg-9">
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} >
          {formik => {
              return (
                  <Form>
                    <div className="fieldset">
                      <fieldset>
                      <legend>Contact Details</legend>
                    <FormikControl control='input' type='text' label='Main Mobile' name='mobile' disabled={!isEdit}/>
                    <FormikControl control='input' type='text' label='Additional Mobile' name='mobile1' disabled={!isEdit}/>
                    <FormikControl control='input' type='text' label='Additional Mobile' name='mobile2' disabled={!isEdit}/>
                    <FormikControl control='input' type='text' label='Landline' name='landline' disabled={!isEdit} />
                    <FormikControl control='input' type='text' label='Whatsapp' name='whatsapp' disabled={!isEdit} />
                    <FormikControl control='input' type='text' label='Linkedin' name='linkedin' disabled={!isEdit} />
                    <FormikControl control='input' type='text' label='Facebook' name='facebook' disabled={!isEdit} />
                    <FormikControl control='input' type='text' label='Twitter' name='twitter' disabled={!isEdit} />
                    {isEdit && <button className="btn btn-primary" type='submit' disabled={!formik.isValid}>Submit</button>}
                    {!isEdit && <button className="btn btn-primary" type="button" onClick={()=>setIsEdit(true)}>Edit</button>}
                    </fieldset>
                    </div>
                  </Form>
              )
          }}
        </Formik>
      </div>
    </>
  )
}


