import React, { useState } from "react";
import Alert from "../components/Layouts/Alert";
import { Link, Redirect } from "react-router-dom";
import {useSelector, useDispatch} from 'react-redux'
import axios from '../axios';
import { setAlert } from "../actions/alert";
import SectionBlock from './SectionBlock'

export default function Login() {
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const login = async () => {
    let config = {header: {"Content-Type": "Application/json"}};
    await axios.post("/api/users/login", formData, config)
      .then((response)=>{
        console.log(response)
        dispatch({type: "LOGIN_SUCCESS", payload: response.data});
      })
      .catch ((err) => {
        dispatch(setAlert(err, "danger", 7000))
        dispatch({type: "LOGIN_FAIL",});
      })
  }

  const onChange = (e) =>
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

  const { email, password } = formData;

  const onSubmit = async (e) => {
    e.preventDefault();
    login(email, password);
  };

  //Redirect if logged in
  if (auth.isAuthenticated) {
    return <Redirect to="/" />;
  }

  return (
    <div className="container">
    <SectionBlock name="Please Sign-in" />
      <div className="row">
        <div className="col-lg-6">
          <br />

          <center>
            <img src="img/gudigars.png" alt="" className="registerPageLogo" />
            <br />
            Not registered ? Please register <Link to="/register"> here </Link>.
          </center>
        </div>

        <div className="col-lg-5">
          <div className="featured-box featured-box-primary text-left mt-5">
            <div className="box-content">
              <h4 className="color-primary font-weight-semibold text-4 text-uppercase mb-3">
                Login
              </h4>

              <Alert />
              <form
                action="/"
                id="frmSignUp"
                method="post"
                className="needs-validation"
                onSubmit={(e) => onSubmit(e)}
              >
                <div className="form-row">
                  <div className="form-group col">
                    <label className="font-weight-bold text-dark text-2">
                      E-mail ID
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter your Email ID"
                      name="email"
                      value={email}
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-lg-12">
                    <label className="font-weight-bold text-dark text-2">
                      Password
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      name="password"
                      placeholder="Enter your Password"
                      value={password}
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-lg-9"></div>
                  <div className="form-group col-lg-3">
                    <input
                      type="submit"
                      defaultValue="Register"
                      className="btn btn-primary btn-sm btn-modern float-right"
                      data-loading-text="Loading..."
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
