import React, {Component} from 'react';
import { HashLink } from "react-router-hash-link";

class SideBar extends Component{
    render(){
        const data = this.props.data;
        const special = ['zeroth','first', 'second', 'third', 'fourth', 'fifth', 'sixth', 'seventh', 'eighth', 'ninth', 'tenth', 'eleventh', 'twelfth', 'thirteenth', 'fourteenth', 'fifteenth', 'sixteenth', 'seventeenth', 'eighteenth', 'nineteenth'];
        const deca = ['twent', 'thirt', 'fort', 'fift', 'sixt', 'sevent', 'eight', 'ninet'];

        const stringifyNumber= (n) => {
            if (n < 20) return special[n];
            if (n%10 === 0) return deca[Math.floor(n/10)-2] + 'ieth';
            return deca[Math.floor(n/10)-2] + 'y-' + special[n%10];
        }

        return(
            <div className="col-lg-3">
                <aside
                className="sidebar"
                id="sidebar"
                data-plugin-sticky
                data-plugin-options="{'minWidth': 991, 'containerSelector': '.container', 'padding': {'top': 110}}"
                >
                    <ul className="nav nav-list flex-column mb-5">
                        {
                            data.map(
                                (item, index)=>
                                    <li className="nav-item">
                                        <HashLink 
                                            className="nav-link" 
                                            data-hash 
                                            data-hash-offset={95} 
                                            to={"#".concat(stringifyNumber(index+1))} >
                                                {item.name}
                                        </HashLink>
                                    </li>
                                )
                        }
                        </ul>
                </aside>
            </div>

        );
    }
}

export default SideBar;
