import React from 'react'
import {useSelector} from 'react-redux'
import SectionBlock from '../../SectionBlock'
import Sidebar from "./Sidebar";
import MyProfile from './MyProfile'
import Family from './FamilyProfile'
import Language from './LanguageProfile'
import Contact from './ContactProfile'
import Identity from './NationalIdentityProfile'
import PhysicalFeatures from './PhysicalFeaturesProfile'
import Profession from './ProfessionalProfile'
import Awards from './AwardsProfile'
import Address from './AddressProfile'

export default function Profile(props) {
    const username = useSelector(state => state.auth.user.username);
    const profile = useSelector(state => state.profile.currentProfile);
    const welcomeMessage = () => {
        if (username) return "Welcome, " + username
        else return "Welcome"
    }
    const showProfilePage = () =>{
        if (profile === 'Personal'){
            return <MyProfile/>
        }else if (profile === 'Family'){
            return <Family/>
        }else if (profile === 'Language'){
            return <Language/>
        }else if (profile === 'Contact'){
            return <Contact/>
        }else if (profile === 'Identity'){
            return <Identity/>
        }else if (profile === 'Features'){
            return <PhysicalFeatures/>
        }else if (profile === 'Profession'){
            return <Profession/>
        }else if (profile === 'Awards'){
            return <Awards/>
        }else if (profile === 'Address'){
            return <Address/>
        }else{
            return <div></div>
        }
    }

    return (
        <>
            <div className="container">
            <SectionBlock name={welcomeMessage()}/>
            <div className="row">
                <Sidebar />
                {showProfilePage()}
            </div>
            </div>
        </>
    )
}
