import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getCurrentProfile } from "../../actions/profile";
import PropTypes from "prop-types";
import Spinner from "../Layouts/Spinner";

import { Link } from "react-router-dom";

const Dashboard = ({
  getCurrentProfile,
  auth: { user },
  profile: { profile, loading },
}) => {
  useEffect(() => {
    getCurrentProfile();
    window.location = "/myprofile";
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return loading && profile === null ? (
    <Spinner />
  ) : (
    <div className="body">
      <div role="main" className="main">
        <section className="page-header page-header-classic">
          <div className="container">
            <div className="row">
              <div className="col">
                <ul className="breadcrumb">
                  <li>
                    <Link to="/dashboard">Dashboard</Link>
                  </li>
                  <li className="active">Pages</li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col p-static">
                <h1 data-title-border>Welcome , {user && user.username} </h1>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

Dashboard.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
});

export default connect(mapStateToProps, { getCurrentProfile })(Dashboard);
