import React, {useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import {postProfile} from '../../../actions/profile'
import { Formik, Form } from 'formik'
import FormikControl from '../../formik/FormikControl'
import * as Yup from 'yup'
import './profile.scss'

export default function Family(props) {
  const dispatch = useDispatch()
  const user = useSelector(state => state.auth.user);
  const [isEdit, setIsEdit] = useState(false);

  let profileObject = require('./profileObject.json')
  
  const profile = user && user.profile
  let keylist = Object.keys(profileObject)
  keylist.map((key)=>{
    if (profile[key]) 
      return profileObject[key] = profile[key]
    return null
  })
  const initialValues = profileObject.family

  const validationSchema = Yup.object({
    spouse:               Yup.string(),
    father:               Yup.string(),
    mother:               Yup.string(),
    grandFather:          Yup.string(),
    grandMother:          Yup.string(),
    maternalGrandFather:  Yup.string(),
    maternalGrandMother:  Yup.string(),
  })

  const onSubmit = (values, submitProps) => {
    // console.log('Form data', JSON.parse(JSON.stringify(values)))
    let formData = {...profileObject, family: values}
    let newUser = user
    newUser.profile = formData
    postProfile(dispatch, newUser, user._id)
    setIsEdit(false)
  }

  return (
    <>
      <div className="col-lg-9">
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} >
          {formik => {
              return (
                  <Form>
                    <div className="fieldset">
                      <fieldset>
                      <legend>Family Details</legend>
                    <FormikControl control='input' type='text' label='Spouse' name='spouse' disabled={!isEdit} />
                    <FormikControl control='input' type='text' label='Father' name='father' disabled={!isEdit}/>
                    <FormikControl control='input' type='text' label='Mother' name='mother' disabled={!isEdit} />
                    <FormikControl control='input' type='text' label='GrandFather' name='grandFather' disabled={!isEdit} />
                    <FormikControl control='input' type='text' label='GrandMother' name='grandMother' disabled={!isEdit} />
                    <FormikControl control='input' type='text' label='Maternal GrandFather' name='maternalGrandFather' disabled={!isEdit} />
                    <FormikControl control='input' type='text' label='Maternal GrandMother' name='maternalGrandMother' disabled={!isEdit} />
                    {isEdit && <button className="btn btn-primary" type='submit' disabled={!formik.isValid}>Submit</button>}
                    {!isEdit && <button className="btn btn-primary" type="button" onClick={()=>setIsEdit(true)}>Edit</button>}
                    </fieldset>
                    </div>
                  </Form>
              )
          }}
        </Formik>
      </div>
    </>
  )
}


