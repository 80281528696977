import React from 'react'
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import './navbar.scss'

export default function Navbar(props) {
    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch()

    const logout = () => {
        dispatch({
        type: "CLEAR_PROFILE",
        });
        dispatch({
        type: "LOGOUT",
        });
    };

    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-dark bg-primary">
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#main_nav">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="main_nav">
                    <ul className="navbar-nav">
                        <li className="nav-item active"> <Link className="nav-link" to="/">Home </Link> </li>
                        <li className="nav-item"><Link className="nav-link" to="/about"> About ABGS</Link></li>
                        <li className="nav-item dropdown">
                            <Link className="nav-link dropdown-toggle" to="/historyofgudigars" data-toggle="dropdown">  History  </Link>
                            <ul className="dropdown-menu">
                                <li><Link className="dropdown-item" to="/historyofgudigars"> History of Gudigars </Link></li>
                                <li><Link className="dropdown-item" to="/gudigararts"> Gudigar Arts </Link></li>
                                <li><Link className="dropdown-item" to="/recognitions"> Recognitions </Link></li>
                                <li><Link className="dropdown-item" to="/socialcause"> Social cause </Link></li>
                                <li><Link className="dropdown-item" to="/acknowledgements"> Acknowledgements </Link></li>
                            </ul>
                        </li>
                        <li className="nav-item"><Link className="nav-link" to='/contact'>Contact Us</Link></li>
                    </ul>

                    <ul className="navbar-nav ml-auto">
                        {(!auth.isAuthenticated) && 
                            <>
                                <li className="nav-item"><Link className="nav-link" to="/register"> Register </Link></li>
                                <li className="nav-item"><Link className="nav-link" to="/login"> Login </Link></li>
                            </>
                        }
                        {(auth.isAuthenticated) &&
                            <li className="nav-item dropdown">
                                <Link className="nav-link  dropdown-toggle" to="/profile" data-toggle="dropdown"> {(auth.isAuthenticated && auth.user)?auth.user.username:'My Account'} </Link>
                                <ul className="dropdown-menu dropdown-menu-right">
                                    <li><Link className="dropdown-item" to="/profile"> Profile</Link></li>
                                    <li><Link className="dropdown-item" to="/" onClick= {() => logout()}> Logout </Link></li>
                                </ul>
                            </li>
                        }
                    </ul>
                </div>
            </nav>

        </>
    )
}
