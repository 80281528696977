import React, { useState } from 'react';
import { Link } from "react-router-dom";

const Features = () => {
    const [module] = useState({})
    module.name = "Features"
    module.isEnabled = true
    module.isAdmin = false
    module.title = "We intend to provide the following features in the near future, please hang on";
    module.data = [
        {
            icon: "fa fa-history text-color-primary",
            title: "History and Culture",
            description: "To serve as a platform for sharing the history and culture of Gudigars",
            link: '/historyofgudigars'
        },
        {
            icon: "fa fa-users text-color-primary",
            title: "Know about activities of ABGS",
            description: "Follow and share the doings and activities of the core committee members of Akhila Bharat Gudigar Samaj",
            link: '#'
        },
        {
            icon: "fa fa-share text-color-primary",
            title: "News and Media",
            description: "Share Gudigar news appearing in other print media; like Sugandhavani, etc.",
            link: '#'
        },
        {
            icon: "fas fa-bullhorn text-color-primary",
            title: "Advertising Platform",
            description: "Create a platform for advertising about sandalwood artifacts, and other commercial goods made by the community people",
            link: '#'
        },
        {
            icon: "fas fa-network-wired text-color-primary",
            title: "Social Network",
            description: "We intend to help you connect with your long lost relatives, friends, and will help you make new friends. This could help plan reunion of families and friends",
            link: '#'
        },
        {
            icon: "fas fa-paint-brush text-color-primary",
            title: "Workshops & Exhibitions",
            description: "Showcase community activities workshops, exhibitions, live updates of sports meet, summer camps for kids, annual results of various exams, news about opportunity for jobs and education, career counseling.",
            link: '#'
        },
        {
            icon: "fas fa-photo-video text-color-primary",
            title: "Photo & Video Gallery",
            description: "Provide a photo/video gallery of artwork from the community, covering events like meetings, weddings, poojas, upanayana, housewarming etc.",
            link: '#'
        },
        {
            icon: "fas fa-female text-color-primary",
            title: "Mahila Samaj",
            description: "Platform for Mahila Samaj activities globally.",
            link: '#'
        },
        {
            icon: "fas fa-trophy text-color-primary",
            title: "Games",
            description: "Arrange extra curricular activities for the community.",
            link: '#'
        },
        {
            icon: "fas fa-plus-square text-color-primary",
            title: "Health Care",
            description: "Proivide health care guidance, news, counseling, blood/organ donation camps and much more",
            link: '#'
        },
        {
            icon: "fas fa-th-list text-color-primary",
            title: "Gudigar Directory",
            description: "Maintain who’s who and contact details of the community members.",
            link: '#'
        },
        {
            icon: "fas fa-heart text-color-primary",
            title: "Gudigar Matrimony",
            description: "Search the best match for your children",
            link: '#'
        },
        {
            icon: "fas fa-shopping-cart text-color-primary",
            title: "Gudigar Marketplace",
            description: "Sell your products in the global Market. Get visibility.",
            link: '#'
        },
    ];

    return(
        <div className="container mb-5 pb-4">
            <div className="row">
                <div className="col mb-4">{/* <hr className="my-5" /> */}</div>
            </div>
            <div className="row pb-3">
                <div className="col-lg-12">
                    <h2 className="font-weight-normal text-7">{module.title}</h2>
                    <div className="row">
                            {module.data.map(
                                    (item, index) => 
                                    <div className="col-sm-4" key={index} >
                                        <div className="feature-box feature-box-style-6">
                                            <Link to={item.link}>
                                            <div className="feature-box-icon">
                                                <i className={item.icon} />
                                                {/* <a href={`${item.link}`}><i className={item.icon} /></a> */}
                                            </div>
                                            </Link>
                                            <div className="feature-box-info">
                                                <h4 className="font-weight-bold text-4 mb-0">{item.title}</h4>
                                                <p className="mb-4">
                                                    {item.description}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                    </div>
                </div>
            </div>
        </div>
    );

}

export default Features;

