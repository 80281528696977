import React from 'react';

// const SectionBlock = (name) => {
class SectionBlock extends React.Component{
    render(){
        return (
            <section>
                <div className="container page-header page-header-classic">
                    <div className="row">
                        <div className="col p-static">
                            <h1 data-title-border>{this.props.name}</h1>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default SectionBlock;
