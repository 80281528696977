import React, {Component, Fragment} from 'react';

class VisionMission extends Component{
    render(){
        const data = this.props.data;
        const blocks = data.blocks;
        return (
            <Fragment>
                <h2 className="font-weight-extra-normal text-7 mb-2" id="second">
                    <strong className="font-weight-extra-bold">{data.name}</strong>
                </h2>
                <div className="row">
                    {
                        blocks.map(
                            (item, index) => 
                                <div className="col-lg-6 ">
                                    <center>
                                    <img src={item.filename} alt="" width="200" />
                                    </center>
                                    <p>{item.text}</p>
                                </div>
                        )
                    }
                </div>
            </Fragment>
        )
    }
}

export default VisionMission;
