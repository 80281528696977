import React from "react";
import { Link } from "react-router-dom";
import {setCurrentProfile} from './profile.actions'
import {useDispatch} from 'react-redux'

export default function Sidebar(props) {
  const dispatch = useDispatch();
  const onClickHandle = (e) => {
    setCurrentProfile(dispatch, e.target.id)
  }
  return (
    <div className="col-lg-3 mt-4 mt-lg-0">
      <aside className="sidebar mt-2" id="sidebar">
        <ul className="nav nav-list flex-column mb-5">
          <li className="nav-item">
            <Link className="nav-link " id="Personal" to="#" onClick={onClickHandle}>
              Personal
            </Link>
          </li>

          <li className="nav-item">
            <Link className="nav-link " id="Family" to="#" onClick={onClickHandle}>
              Family
            </Link>
          </li>

          <li className="nav-item">
            <Link className="nav-link " id="Language" to="#" onClick={onClickHandle}>
              Language
            </Link>
          </li>

          <li className="nav-item">
            <Link className="nav-link " id="Identity" to="#" onClick={onClickHandle}>
              National Identity
            </Link>
          </li>

          <li className="nav-item">
            <Link className="nav-link " id="Awards" to="#" onClick={onClickHandle}>
              Awards
            </Link>
          </li>

          <li className="nav-item">
            <Link className="nav-link " id="Features" to="#" onClick={onClickHandle}>
              Physical Features
            </Link>
          </li>

          <li className="nav-item">
            <Link className="nav-link " id="Profession" to="#" onClick={onClickHandle}>
              Professional
            </Link>
          </li>

          <li className="nav-item">
            <Link className="nav-link " id="Address" to="#" onClick={onClickHandle}>
              Address
            </Link>
          </li>

          <li className="nav-item">
            <Link className="nav-link " id="Contact" to="#" onClick={onClickHandle}>
              Contact
            </Link>
          </li>
        </ul>
      </aside>
    </div>
  );
}


// <div className="profile-image-outer-container">
//   <div className="profile-image-inner-container bg-color-primary">
//     <img src={`http://${user === null? "gudigars.com/img/avatars/avatar-2.png": user.avatar}`} alt=""/>
//     <span className="profile-image-button bg-color-dark"><i className="fas fa-camera text-light" /></span>
//   </div>
//   <form action="/upload" method="post" encType="multipart/form-data">
//     <input
//       type="file"
//       name="file"
//       id="file"
//       className="profile-image-input"
//       accept="image/*"
//       onChange={handleChange}
//     />
//   </form>
// </div>
