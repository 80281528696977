import React, {useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import {postProfile} from '../../../actions/profile'
import { Formik, Form } from 'formik'
import FormikControl from '../../formik/FormikControl'
import * as Yup from 'yup'
import './profile.scss'

export default function Language(props) {
  const dispatch = useDispatch()
  const user = useSelector(state => state.auth.user);
  const [isEdit, setIsEdit] = useState(false);

  let profileObject = require('./profileObject.json')
  
  const profile = user && user.profile
  let keylist = Object.keys(profileObject)
  keylist.map((key)=>{
    if (profile[key]) 
      return profileObject[key] = profile[key]
    return null
  })
  const initialValues = profileObject.address

  const validationSchema = Yup.object({
    language:   Yup.string(),
  })

  const onSubmit = (values, submitProps) => {
    // console.log('Form data', JSON.parse(JSON.stringify(values)))
    let formData = {...profileObject, address: values}
    let newUser = user
    newUser.profile = formData
    postProfile(dispatch, newUser, user._id)
    setIsEdit(false)
  }

  return (
    <>
      <div className="col-lg-9">
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} >
          {formik => {
              return (
                  <Form>
                    <div className="fieldset">
                      <fieldset>
                      <legend>Current Address</legend>
                      <FormikControl control='input' type='text' label='address' name='current.address' disabled={!isEdit}/>
                      <FormikControl control='input' type='text' label='addressLine1' name='current.addressLine1' disabled={!isEdit}/>
                      <FormikControl control='input' type='text' label='addressLine2' name='current.addressLine2' disabled={!isEdit}/>
                      <FormikControl control='input' type='text' label='addressLine3' name='current.addressLine3' disabled={!isEdit}/>
                      <FormikControl control='input' type='text' label='city' name='current.city' disabled={!isEdit}/>
                      <FormikControl control='input' type='text' label='state' name='current.state' disabled={!isEdit}/>
                      <FormikControl control='input' type='text' label='pin' name='current.pin' disabled={!isEdit}/>
                      <FormikControl control='input' type='text' label='country' name='current.country' disabled={!isEdit}/>
                      </fieldset>
                    </div>
                    <div className="fieldset">
                      <fieldset>
                      <legend>Permanent Address</legend>
                      <FormikControl control='input' type='text' label='address' name='permanent.address' disabled={!isEdit}/>
                      <FormikControl control='input' type='text' label='addressLine1' name='permanent.addressLine1' disabled={!isEdit}/>
                      <FormikControl control='input' type='text' label='addressLine2' name='permanent.addressLine2' disabled={!isEdit}/>
                      <FormikControl control='input' type='text' label='addressLine3' name='permanent.addressLine3' disabled={!isEdit}/>
                      <FormikControl control='input' type='text' label='city' name='permanent.city' disabled={!isEdit}/>
                      <FormikControl control='input' type='text' label='state' name='permanent.state' disabled={!isEdit}/>
                      <FormikControl control='input' type='text' label='pin' name='permanent.pin' disabled={!isEdit}/>
                      <FormikControl control='input' type='text' label='country' name='permanent.country' disabled={!isEdit}/>
                      </fieldset>
                    </div>
                    {isEdit && <button className="btn btn-primary" type='submit' disabled={!formik.isValid}>Submit</button>}
                    {!isEdit && <button className="btn btn-primary" type="button" onClick={()=>setIsEdit(true)}>Edit</button>}
                  </Form>
              )
          }}
        </Formik>
      </div>
    </>
  )
}


