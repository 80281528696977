import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Landing from "./components/Landing";
import Register from "./components/Register";
import Login from "./components/Login";
import Contact from './components/Contact';
import About from "./components/About";
import Gallery from "./components/Gallery";
import ExMembers from "./components/ExMembers";
// import History from "./components/History";
import History1 from "./components/history/historyComponent1";
import History2 from "./components/history/historyComponent2";
import History3 from "./components/history/historyComponent3";
import History4 from "./components/history/historyComponent4";
import History5 from "./components/history/historyComponent5";
import PrivateRoute from "./components/routing/PrivateRoute";
import Dashboard from "./components/Dashboard/Dashboard";
import Header from "./components/Header";
import Footer from "./components/Footer";
import setAuthToken from "./utils/setAuthToken";
import Profile from "./components/Dashboard/Profiles/Profile";
//Redux
import { Provider } from "react-redux";
import store from "./store";
import { loadUser } from "./actions/auth";

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <div className="container">
          <Header />
          <Switch>
            <Route exact path="/" component={Landing} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/about" component={About} />
            <Route exact path="/gallery" component={Gallery} />
            <Route exact path="/ex_members" component={ExMembers} />

            <Route exact path="/historyofgudigars" component={History1} />
            <Route exact path="/gudigararts" component={History2} />
            <Route exact path="/recognitions" component={History3} />
            <Route exact path="/socialcause" component={History4} />
            <Route exact path="/acknowledgements" component={History5} />

            <PrivateRoute exact path="/dashboard" component={Dashboard} />
            <PrivateRoute exact path="/profile" component={Profile} />
          </Switch>
          <Footer />
        </div>
      </Router>
    </Provider>
  );
};

export default App;
