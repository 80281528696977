import React from 'react'

export default function Title(props) {
    

    return (
        <>
            <h2>{props.children}</h2>
        </>
    )
}
