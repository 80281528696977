import React from 'react'

export default function Imageobject(props) {
    
    return (
        <>
            <div className="imageBox">
                <img src={props.src} alt=""/>
                {props.children}
            </div>
        </>
    )
}
