import axios from "../axios";

import { GET_PROFILE, PROFILE_ERROR, POST_PROFILE } from "./types";

//Get current user profile
export const getCurrentProfile = async (dispatch) => {
    await axios.get("/api/members/me")
    .then((res)=>{dispatch({type: GET_PROFILE, payload: res.data})})
    .catch((err)=>{dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    })})
}

export const postProfile = async (dispatch, formData, id) => {
  try {
    // console.log(formData)
    const res = await axios.post("/api/users/profile/"+id, formData);

    dispatch({
      type: POST_PROFILE,
      payload: res.data,
    });
  } catch (err) {
    console.log(err)
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

