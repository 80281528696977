import React, { useState } from 'react';

const Announcement = () => {
  const [module] = useState({})
  
  module.name = "Announcement"
  module.isEnabled = false
  module.isAdmin = false
  
  module.listOfAnnouncements = ["We hearty welcome all the gudigars for Gudigars Official Website opening ceremony.","This code developed by Poornachandra"];
  module.title = "Announcement";
  
  if (module.isEnabled && !module.isAdmin){
    return(
      <div role="main" className="container">
        <h5 className="word-rotator letters type mb-3 mt-3 ">
          <span className="text-primary font-weight-bold alternative-font-2 ">
            <mark className="text-white bg-color-primary">{module.title}</mark>{" "}:{" "}
          </span>
          <span className="word-rotator-words waiting  text-color-quaternary">
              {module.listOfAnnouncements.map((item,index) => <b className={(index===0)?"is-visible":""}>{item}</b>)}
          </span>
        </h5>
      </div>
    );
  }else{
    return(<div></div>);
  }
}

export default Announcement;
