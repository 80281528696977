import React, {useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import {postProfile} from '../../../actions/profile'
import { Formik, Form } from 'formik'
import FormikControl from '../../formik/FormikControl'
import * as Yup from 'yup'
import './profile.scss'

export default function Myprofile(props) {
  const dispatch = useDispatch()
  const user = useSelector(state => state.auth.user);
  const [isEdit, setIsEdit] = useState(false);

  let profileObject = require('./profileObject.json')
  
  const profile = user && user.profile
  let keylist = Object.keys(profileObject)
  keylist.map((key)=>{
    if (profile[key]) 
      return profileObject[key] = profile[key]
    return null
  })
  const initialValues = profileObject.personal

  const validationSchema = Yup.object({
    salutation:   Yup.string(),
    firstName:    Yup.string(),
    middleName:   Yup.string(),
    familyName:   Yup.string(),
    nickName:     Yup.string(),
    mobile:       Yup.string(),
    birthDate:    Yup.date(),
  })

  const onSubmit = (values, submitProps) => {
    // console.log('Form data', JSON.parse(JSON.stringify(values)))
    let formData = {...profileObject, personal: values}
    let newUser = user
    newUser.profile = formData
    postProfile(dispatch, newUser, user._id)
    setIsEdit(false)
  }

  const salutationOptions = [
    { key: 'Select a salutation', value: '' },
    { key: 'Mr.', value: 'Mr.' },
    { key: 'Mrs.', value: 'Mrs.' },
    { key: 'Miss.', value: 'Miss.' },
    { key: 'Dr.', value: 'Dr.' },
    { key: 'Col.', value: 'Col.' },
  ]

  return (
    <>
      <div className="col-lg-9">
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} >
          {formik => {
              return (
                  <Form>
                    <div className="fieldset">
                      <fieldset>
                      <legend>Personal Details</legend>
                    <FormikControl control='select' type='text' label="Salutation" name='salutation' options={salutationOptions} disabled={!isEdit} />
                    <FormikControl control='input' type='text' label='First Name' name='firstName' disabled={!isEdit}/>
                    <FormikControl control='input' type='text' label='Middle Name' name='middleName' disabled={!isEdit} />
                    <FormikControl control='input' type='text' label='Surname' name='familyName' disabled={!isEdit} />
                    <FormikControl control='input' type='text' label='Calling Name (nickname)' name='nickName' disabled={!isEdit} />
                    <FormikControl control='input' type='text' label='Mobile' name='mobile' disabled={!isEdit} />
                    <FormikControl control='simpledate' type='date' label='Date of Birth' name='birthDate' disabled={!isEdit} />
                    {isEdit && <button className="btn btn-primary" type='submit' disabled={!formik.isValid}>Submit</button>}
                    {!isEdit && <button className="btn btn-primary" type="button" onClick={()=>setIsEdit(true)}>Edit</button>}
                    </fieldset>
                    </div>
                  </Form>
              )
          }}
        </Formik>
      </div>
    </>
  )
}


