import React, { Component } from "react";
import SectionBlock from "./SectionBlock";
import AboutABGS from "./AboutABGS";
import VisionMission from "./visionMission";
import WorkingCommittee from "./workingCommittee";
import PresidentMessage from "./PresidentMessage";
import SideBar from "./sideBar";
class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      module: {
        name: "About Us",
        isEnabled: true,
        isAdmin: false,
        sections: [
          {
            name: "ABGS",
            blockType: "single",
            image: false,
            text:
              "Akhila Bharat Gudigar Samaj, popularly known as A.B.G.S, was founded in early 196s by the leaders of Gudigar community from different areas under the presidentship of S Devappa of Sagar. This was formed with the aim of upliftment of the community people in the field of art and education. The executive body was re-registered on 21st July 1997 under the Societies Registration Act 1960. I. It is a Non-Profit Non-Government Organization (NGO) working relentlessly since its inception for the welfare of the society, in general, and the Gudigar Community, in particular. The present president of ABGS is Harish L Shetty from Kumta.",
          },

          {
            name: "President's Message",
            blockType: "single",
            image: false,
            text: `ಅಧ್ಯಕ್ಷರ ಸಂದೇಶ: 

ಗುಡಿಗಾರರ ವೆಬ್ಸೈಟ್ ಅನ್ನು ನಮ್ಮ ಸಮಾಜದ ಉತ್ಸಾಹಿ ತರುಣರು ಅತ್ಯಂತ ಸುಂದರವಾಗಿ ಮಾಡುತ್ತಿದ್ದಾರೆ. ವಿಶ್ವಮಟ್ಟದಲ್ಲಿ ನಮ್ಮ ಕಲೆ, ಸಾಹಿತ್ಯ, ನಮ್ಮ ಸಮಾಜ ನಡೆದು ಬಂದ ದಾರಿ ಎಲ್ಲರಿಗೂ ಅವಲೋಕನ ಆಗುತ್ತಿದೆ. ಮುಂದೆ ಸಮಾಜದ ಎಲ್ಲ ಬಂಧುಭಗಿನಿಯರೂ ಇದರ ಮೆಂಬರ್ ಶಿಪ್ ಮಾಡಿ ತಮ್ಮ ಕಲೆ, ಸಾಹಿತ್ಯಗಳನ್ನು ಈ ವೆಬ್ಸೈಟ್ ಮುಖಾಂತರ ವಿಶ್ವಕ್ಕೆ ತಿಳಿಸುವಂತಾಗಲಿ. ಸಮಾಜದ ಎಲ್ಲ ಕಲಾವಿದರಿಗೆ ಇದರಿಂದ ಹೆಚ್ಚಿನ ಮಾನ್ಯತೆ ಸಿಗುವಂತಾಗಲಿ ಎಲ್ಲರಿಗೂ ಶುಭವಾಗಲಿ ಎಂದು ಸಮಾಜದ ಅಧ್ಯಕ್ಷನಾಗಿ ನಾನು ಹಾರೈಸುತ್ತೇನೆ. 

ಹರೀಶ ಎಲ್ ಶೆಟ್ಟಿ 

President's message:  

Gudigar's website has been nicely created by the enthusiastic youth of our community. This will allow the art and culture of our society to be viewed by the whole world. I request all the members of Gudigar community to come forward to become members of the website. May our artists showcase their art to the world through this website. As the president of the society, I wish all  the artists of the society a greater recognition. I wish you all good luck.
 
Harish L Shetty`,
          },

          {
            name: "Vision and Mission",
            blockType: "multiple",
            blocks: [
              {
                image: true,
                filename: "/img/vision.png",
                text:
                  "To be an exclusive internet portal for Gudigar community’s online requirements and for showcasing Gudigar’s talent to the world.",
              },
              {
                image: true,
                filename: "/img/mission.png",
                text:
                  "Gudigars.com aims to create the most preferred web-based platform to let the whole world know about Gudigar’s rich heritage of arts and to provide the community members with an interactive database and resource for all their online requirements.",
              },
            ],
          },
          {
            name: "The Working committee",
            blockType: "multiple",
            blocks: [
              {
                filename: "img/abgs2/Slide1.jpg",
                name: "Harish Shetty",
                role: "President",
              },
              {
                filename: "img/abgs2/Slide2.jpg",
                name: "Gurumurthy Maroor",
                role: "Honorary President",
              },
              {
                filename: "img/abgs2/Slide3.jpg",
                name: "Puttaswamy Gudigar",
                role: "Vice President",
              },
              {
                filename: "img/abgs2/Slide4.jpg",
                name: "Vijaykumar J A",
                role: "Vice President",
              },
              {
                filename: "img/abgs2/Slide5.jpg",
                name: "Bhuvaneshwari Gudigar",
                role: "Mahilasamaj President",
              },
              {
                filename: "img/abgs2/Slide6.jpg",
                name: "Mahesh A Gudigar",
                role: "General Secretary",
              },
              {
                filename: "img/abgs2/Slide7.jpg",
                name: "Gajanana Gudigar",
                role: "Organizing Secretary",
              },
              {
                filename: "img/abgs2/Slide8.jpg",
                name: "Prabhakara S R",
                role: "Treasurer",
              },
              {
                filename: "img/abgs2/Slide9.jpg",
                name: "Jagadeesh S D",
                role: "Regional President (Sagar)",
              },
              {
                filename: "img/abgs2/Slide10.jpg",
                name: "Ragavendra S Jade",
                role: "Regional President (Soraba)",
              },
              {
                filename: "img/abgs2/Slide11.jpg",
                name: "Ganesh S C",
                role: "Regional President (Udupi)",
              },
              {
                filename: "img/abgs2/Slide12.jpg",
                name: "Ashok G Gudigar",
                role: "Regional President (Sirsi)",
              },
              {
                filename: "img/abgs2/Slide13.jpg",
                name: "Ravishankar D S",
                role: "Regional President (Shivamogga)",
              },
              {
                filename: "img/abgs2/Slide14.jpg",
                name: "S Venkatesh",
                role: "Regional President (Bengaluru)",
              },
              {
                filename: "img/abgs2/Slide15.jpg",
                name: "Madhav Kurdekar",
                role: "Regional President (Mumbai)",
              },
              {
                filename: "img/abgs2/Slide16.jpg",
                name: "Nagesh V Chitari",
                role: "Regional President (Goa)",
              },
              {
                filename: "img/abgs2/Slide17.jpg",
                name: "Ishwar Anand Shetty",
                role: "Salaha Samithi (Mumbai)",
              },
              {
                filename: "img/abgs2/Slide18.jpg",
                name: "Ganesha Vamana Chitrak",
                role: "Salaha Samithi (Bengaluru)",
              },
              {
                filename: "img/abgs2/Slide19.jpg",
                name: "Dr. Balachandra Bandodkar",
                role: "Salaha Samithi (Bengaluru)",
              },

              {
                filename: "img/abgs2/Slide20.jpg",
                name: "Ravi Sagar",
                role: "Salaha Samithi (Bengaluru)",
              },
              {
                filename: "img/abgs2/Slide21.jpg",
                name: "Suryakant Gudigar",
                role: "Salaha Samithi (Sirsi)",
              },
              {
                filename: "img/abgs2/Slide22.jpg",
                name: "Dr. Shivaprakash D S",
                role: "Salaha Samithi (Bhadravati)",
              },
              {
                filename: "img/abgs2/Slide23.jpg",
                name: "Nitin Chitari",
                role: "Salaha Samithi (Goa)",
              },
              {
                filename: "img/abgs2/Slide24.jpg",
                name: "Dr. Arun Kumar",
                role: "Salaha Samithi (Mattinavale)",
              },
              {
                filename: "img/abgs2/Slide25.jpg",
                name: "Arun N Shetty",
                role: "Salaha Samithi (Uttara Kannada)",
              },
            ],
          },
        ],
      },
    };
  }

  render() {
    const module = this.state.module;
    return (
      <div className="container">
        <div role="main" className="main">
          <SectionBlock name={module.name} />
          <div className="container py-1">
            <div className="row">
              <SideBar data={module.sections} />
              <div className="col-lg-9 card border-0 border-radius-0 bg-color-grey">
                <AboutABGS data={module.sections[0]} />
                <hr />
                <PresidentMessage data={module.sections[1]} />
                <hr />
                <VisionMission data={module.sections[2]} />
                <hr />
                <WorkingCommittee data={module.sections[3]} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default About;
