import React, { useState } from "react";
import Announcement from "./announcement";
import Features from "./features";
import { Link } from "react-router-dom";
import {useSelector} from 'react-redux';
import SectionBlock from './SectionBlock'
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from "reactstrap";

const items = [
  {
    src: "img/slides/Bannar1_new.jpg",
    altText: "Slide 1",
    caption: "Gudigars.com"

  },
  {
    src: "img/slides/Bannar2_new.jpg",
    altText: "Slide 2",
    caption: "Gudigars.com"
  },
  {
    src: "img/slides/Bannar3_new.jpg",
    altText: "Slide 3",
    caption: "Gudigars.com"
  },
  {
    src: "img/slides/Bannar4_new.jpg",
    altText: "Slide 4",
    caption: "Gudigars.com"
  },
  {
    src: "img/slides/Bannar5_new.jpg",
    altText: "Slide 5",
    caption: "Gudigars.com"
  },
  // {
  //   src: "img/slides/Bannar6_new.jpg",
  //   altText: "Slide 6",
  // },
];

const Landing = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const auth = useSelector(state => state.auth);
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        <img src={item.src} alt={item.altText} />
        <CarouselCaption
          captionText={item.caption}
          captionHeader={item.caption}
        />
      </CarouselItem>
    );
  });

  return (
    <div className="container">
      <SectionBlock name="Welcome to the Gudigars"/>
      <Announcement />
      <Carousel activeIndex={activeIndex} next={next} previous={previous}>
        <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
        {slides}
        <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
        <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
      </Carousel>
      {!auth.isAuthenticated &&
        <div className="home-intro bg-primary" id="home-intro">
          <div className="container ">
            <div className="row align-items-center">
              <div className="col-lg-8">
                <p>
                  Become a member of Gudigars official website and share,
                  connect, engage with community!
                </p>
              </div>
              <div className="col-lg-4">
                <div className="get-started text-left text-lg-right">
                  <Link
                    to="/register"
                    className="btn btn-dark btn-lg text-3 font-weight-semibold px-4 py-3"
                  >
                    Register Now
                  </Link>
                  <div className="learn-more">
                    or <Link to="/about">learn more.</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      <div className="container">
          <div className="row align-items-center mb-5">
            <div className="col-lg-7 pr-5 ">
              <h2 className="font-weight-normal text-6 mb-4">
                <strong className="font-weight-extra-bold">
                  Introduction{" "}
                </strong>
              </h2>

              {/* <p className="lead pr-2 mb-4 drop-caps drop-caps-style-2">
                Gudigars.com is a web-based platform to showcase the community
                of Gudigars. “Gudigar or Gudigara” are a group of people
                residing in the states of Karnataka, Maharashtra, India. The
                traditional profession is to sculpt/build temples and places of
                worship. The sculpting skills are passed on only in the families
                of Gudigars.
              </p> */}
              <p className="lead pr-2 mb-4 drop-caps drop-caps-style-2">
                Gudigars are a micro minority that has depended mainly upon fine
                arts for making a living, while spreading its glory across the
                world due to the contributions in the field of fine arts.
                Traditionally Gudigars were working in different fields of art
                while some of us were also into farming. Very few had depended
                upon other professions. Nowadays more and more Gudigars are into
                different jobs while a smaller number of Gudigars still pursue
                the traditional craft. This website is an effort to introduce to
                the world the great tradition of art of Gudigars, and to help
                Gudigars with a better platform to improve the communication
                within the community and with the rest of the world.
              </p>
              {/* <p className="mb-4">
                They work on pith garlands and coronets and prepare trinkets.
                Earlier many well-to-do Gudigars owned land and were
                agriculturists. Later some excelled in Sandalwood carving, ebony
                and ivory carving. With depletion of Sandalwood growth and
                diminishing forest wealth they took to wood carving. Ivory
                carving is totally banned in India, because of the harm caused
                to elephant tusks with that, the most ancient and precious art
                came to an end.
              </p> */}
              <p className="mb-4">
                We have a dedicated section in the website that discusses the
                possible history of Gudigars, or Chitaris as they are known in
                parts of Maharashtra and Goa.
              </p>
            </div>
            <div className="col-lg-5">
              <img
                src="img/office/our-office-4.png"
                className="img-responsive"
                alt="Office"
              />
            </div>
          </div>
        </div>
      <div role="main" className="main">
      </div>
      <Features />
    </div>
  );
};

export default Landing;
