import React, { Fragment } from "react";

class PresidentMessage extends React.Component {
  render() {
    const data = this.props.data;
    return (
      <Fragment>
        <h2 className="font-weight-extra-normal text-7 mb-2" id="first">
          <strong className="font-weight-extra-bold">{data.name}</strong>
        </h2>
        <p className="new-line">{data.text}</p>
      </Fragment>
    );
  }
}

export default PresidentMessage;
