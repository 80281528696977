import React, { Component, Fragment } from "react";

class WorkingCommittee extends Component {
  render() {
    const data = this.props.data;
    return (
      <Fragment>
        <h2 className="font-weight-extra-normal text-7 mb-2" id="third">
          <strong className="font-weight-extra-bold">{data.name}</strong>
        </h2>
        <div className="container py-4">
          <ul
            className="nav nav-pills sort-source sort-source-style-3 justify-content-center"
            data-sort-id="team"
            data-option-key="filter"
          >
            {/* <li className="nav-item active" data-option-value="*">
                            <a
                            className="nav-link text-1 text-uppercase active"
                            href="!#"
                            rel="noopener noreferrer"
                            >
                            Show All
                            </a>
                        </li> */}
          </ul>
          <div className="">
            <div className="row team-list sort-destination" data-sort-id="team">
              {data.blocks.map((item) => (
                <div className="col-12 col-sm-6 col-lg-4 isotope-item design">
                  <img src={item.filename} className="img-fluid" alt="" />
                </div>
              ))}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default WorkingCommittee;
