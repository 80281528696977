import React, {Fragment} from 'react';

class AboutABGS extends React.Component{
    render(){
        const data = this.props.data;
        return(
            <Fragment>
                <h2 className="font-weight-extra-normal text-7 mb-2" id="first">
                    <strong className="font-weight-extra-bold">{data.name}</strong>
                </h2>
                <p>{data.text}</p>
            </Fragment>
        )
    }
}

export default AboutABGS;
