import React from 'react'
import {useSelector} from 'react-redux'
import Title from './title'
import ImageObject from './imageObject'
import ShowHistoryPages from './showHistoryPages'
import SectionBlock from "../SectionBlock";

export default function HistoryComponent(props) {
    const auth = useSelector(state => state.auth);
    const pages = [
        {
            1:<div>
                    <Title>Acknowledgements: </Title> 
                    <p>My sincere thanks to all the people who have helped with the data and pictures, especially M C Venkatesh for useful discussions, inputs and corrections. Thanks to S G Nagaraj and B. Ashwathnarayana for providing the award lists. As discussed earlier, please let me know if you feel any matter is missing and needs to be included. </p>
                    
                    <Title>Additional references: </Title>
                    <li className="roman"><a href="https://joshuaproject.net/people_groups/16875/IN">https://joshuaproject.net/people_groups/16875/IN</a></li>
                    <li className="roman">G Nagesh Sagar, Prajavani, November 3, 2010, G U Honnavar, Udayavani, December 2, 2019</li>
                    <li className="roman"><a href="https://www.esamskriti.com/e/Culture/Indian-Culture/Masterful-Carvers-of-Rajasthan-1.aspx">https://www.esamskriti.com/e/Culture/Indian-Culture/Masterful-Carvers-of-Rajasthan-1.aspx</a></li>
                    <li className="roman">A. N. Arunkumar et al, Current science, December 2012.</li>
                    <li className="roman"><a href="https://www.finch-and-co.co.uk/artwork-detail/813215/18675/indian-sandalwood-rectangular-casket">https://www.finch-and-co.co.uk/artwork-detail/813215/18675/indian-sandalwood-rectangular-casket</a></li>
                    <li className="roman"><a href="https://www.aschitrak.com/paintings">https://www.aschitrak.com/paintings</a></li>
                    <li className="roman"><a href="http://anandarteffects.com/">http://anandarteffects.com</a></li>
                    <li className="roman"><a href="http://lacma.wordpress.com/2010/11/11/challenges-of-conservation-the-mysore-album-cover-project/">http://lacma.wordpress.com/2010/11/11/challenges-of-conservation-the-mysore-album-cover-project</a></li>
                    
                    <Title>About the author:</Title> 
                    <p>Dr Balachandra S Bandodkar (Balu) is the fourth child of Nirmala and Shankar Anant Shet of Kumta. He did is MSc in Organic Chemistry from Karnatak University with a Gold Medal in 1987 and PhD in Organic Chemistry from Bangalore University in 1992. For the past 28 years he has been working as a pharmaceutical scientist. Balu had been managing editor of Gudigar magazine ‘Sugandhavani’ for about 20 years. He had also been the President of Gudigar Employees and Entrepreneurs Trust (GEET) and a director of Akhila Bharat Gudigar Samaj (ABGS) for different periods.  He is on the advisory panel of the present ABGS. </p>
                    <div className="all-images-in-a-row">
                    <ImageObject src="/img/history/image153.jpg"></ImageObject>
                    </div>
                </div>
        }]
    return (
        <div className="container">
            <SectionBlock name="Acknowledgements" />
            {!auth.isAuthenticated && <>You are not Logged in yet !</>}
            {auth.isAuthenticated && 
            <>
                <ShowHistoryPages pages={pages}/>
           </>
            }
        </div>
    )
}
