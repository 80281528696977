import React, { useState } from "react";
import SectionBlock from "./SectionBlock";
import Loader from "react-loader";

import axios from "axios";

export default function Contact(props) {
  const [formData, setFormData] = useState({
    fullname: "",
    email: "",
    subject: "",
    message: "",
  });

  const [loaded, setLoaded] = useState(true);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const onChange = (e) =>
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

  const onSubmit = async (e) => {
    e.preventDefault();

    setLoaded(false);

    axios
      .post("http://34.123.87.76:8000/api/mail/sendContactMail", formData)
      .then((response) => {
        setLoaded(true);
        setSuccess(true);
        setError(false);
      })
      .catch((error) => {
        setLoaded(true);
        setSuccess(false);
        setError(true);
        console.error("There was an error!", error);
      });

    setFormData({
      fullname: "",
      email: "",
      subject: "",
      message: "",
    });
  };

  return (
    <div className="container">
      <div role="main" className="main">
        <SectionBlock name="Contact Us" />
        <div className="container">
          <div className="row py-4">
            <div className="col-lg-6">
              <div className="overflow-hidden mb-4 pb-3">
                <p className="mb-0 ">
                  Feel free to ask for details, don't save any questions!
                </p>
              </div>

              <Loader loaded={loaded}></Loader>

              {success && (
                <div className="alert alert-success" role="alert">
                  Thank you, Your message was sent successfully!
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              )}
              {error && (
                <div className="alert alert-danger" role="alert">
                  Sorry cannot sent message. Please try again!
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              )}

              <form
                className="contact-form"
                action=""
                method="POST"
                onSubmit={(e) => onSubmit(e)}
              >
                <div className="contact-form-success alert alert-success d-none mt-4">
                  <strong>Success!</strong> Your message has been sent to us.
                </div>
                <div className="contact-form-error alert alert-danger d-none mt-4">
                  <strong>Error!</strong> There was an error sending your
                  message.
                  <span className="mail-error-message text-1 d-block" />
                </div>
                <div className="form-row">
                  <div className="form-group col-lg-6">
                    <label className="required font-weight-bold text-dark text-2">
                      Full Name
                    </label>
                    <input
                      type="text"
                      data-msg-required="Please enter your name."
                      maxLength={100}
                      className="form-control"
                      name="fullname"
                      value={formData.fullname}
                      onChange={(e) => onChange(e)}
                      required
                    />
                  </div>
                  <div className="form-group col-lg-6">
                    <label className="required font-weight-bold text-dark text-2">
                      Email Address
                    </label>
                    <input
                      type="email"
                      data-msg-required="Please enter your email address."
                      data-msg-email="Please enter a valid email address."
                      maxLength={100}
                      className="form-control"
                      value={formData.email}
                      name="email"
                      onChange={(e) => onChange(e)}
                      required
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col">
                    <label className="font-weight-bold text-dark text-2">
                      Subject
                    </label>
                    <input
                      type="text"
                      data-msg-required="Please enter the subject."
                      maxLength={100}
                      className="form-control"
                      value={formData.subject}
                      name="subject"
                      onChange={(e) => onChange(e)}
                      required
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col">
                    <label className="required font-weight-bold text-dark text-2">
                      Message
                    </label>
                    <textarea
                      maxLength={5000}
                      data-msg-required="Please enter your message."
                      rows={8}
                      className="form-control"
                      value={formData.message}
                      name="message"
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col">
                    <input
                      type="submit"
                      defaultValue="Send Message"
                      className="btn btn-primary btn-modern"
                      data-loading-text="Loading..."
                    />
                  </div>
                </div>
              </form>
            </div>
            <div className="col-lg-6">
              <div>
                <br />

                <h4 className="mt-2 mb-1">
                  Registered <strong>Office</strong>
                </h4>
                <ul className="list list-icons list-icons-style-2 mt-2">
                  <li>
                    <i className="fas fa-map-marker-alt top-6" />{" "}
                    <strong className="text-dark">Address:</strong> Akhila
                    Bharata Gudigara Samaja Sree Ramachandra Devasthana, Jog
                    Road, Sagar 577401
                  </li>
                  <li>
                    <i className="fas fa-phone top-6" />{" "}
                    <strong className="text-dark">Phone:</strong> +91 94480
                    14176
                  </li>
                  <li>
                    <i className="fas fa-envelope top-6" />{" "}
                    <strong className="text-dark">Email:</strong>{" "}
                    <a href="mailto:mail@example.com">admin@gudigars.com</a> ,
                    <a href="mailto:mail@example.com">
                      gudigarsworld@gmail.com
                    </a>
                  </li>
                </ul>
              </div>

              <br />

              <center>
                {/* <img src="img/Contact_.jpg" alt="" /> */}
              </center>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

