import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
    <div className="container">
    <footer id="footer">
      <div className="container">
        <div className="footer-ribbon">
          <span>Get in Touch</span>
        </div>
        <div className="row py-1 my-1">
          <div className="col-md-6 col-lg-4 mb-4 mb-lg-0">
            <h2 className="text-3 mb-3">ABOUT GUDIGARS WEBSITE</h2>
            <p className="pr-1">
              “Gudigars.com” is a web interface that connects Gudigars within
              the community with each other and with the rest of the world. It
              aims to create a dedicated platform to let the world know about
              Gudigar’s rich heritage of arts and to provide the community
              members with an interactive database and resource for all their
              online requirements
            </p>
            <div className="alert alert-success d-none" id="newsletterSuccess">
              <strong>Success!</strong> You've been added to our email list.
            </div>
            <div className="alert alert-danger d-none" id="newsletterError" />
            <h2 className="text-3 mb-3">SUBSCRIBE TO OUR NEWSLETTER</h2>
            <form
              id="newsletterForm"
              action="php/newsletter-subscribe.php"
              method="POST"
              className="mr-4 mb-3 mb-md-0"
            >
              <div className="input-group input-group-rounded">
                <input
                  className="form-control form-control-sm bg-light"
                  placeholder="Email Address"
                  name="newsletterEmail"
                  id="newsletterEmail"
                  type="text"
                />
                <span className="input-group-append">
                  <button
                    className="btn btn-light text-color-dark"
                    type="submit"
                  >
                    <strong>GO!</strong>
                  </button>
                </span>
              </div>
            </form>
          </div>
          <div className="col-md-6 col-lg-3 mb-4 mb-lg-0">
            <h5 className="text-5 text-transform-none font-weight-semibold text-color-light mb-4">
              Pages
            </h5>
            <ul className="list list-icons list-icons-sm">
              <li>
                <i className="fas fa-angle-right" />
                <Link to="/" className="link-hover-style-1 ml-1">
                  Home
                </Link>
              </li>
              <li>
                <i className="fas fa-angle-right" />
                <Link to="/about" className="link-hover-style-1 ml-1">
                  About Us
                </Link>
              </li>
              <li>
                <i className="fas fa-angle-right" />
                <Link to="contact" className="link-hover-style-1 ml-1">
                  Contact Us
                </Link>
              </li>
              <li>
                <i className="fas fa-angle-right" />
                <Link to="register" className="link-hover-style-1 ml-1">
                  Register
                </Link>
              </li>
              <li>
                <i className="fas fa-angle-right" />
                <Link to="login" className="link-hover-style-1 ml-1">
                  Login
                </Link>
              </li>
            </ul>
          </div>

          <div className="col-md-6 col-lg-3 mb-4 mb-md-0">
            <div className="contact-details">
              <h5 className="text-3 mb-3">CONTACT US</h5>
              <ul className="list list-icons list-icons-lg">
                <li className="mb-1">
                  <i className="far fa-dot-circle text-color-primary" />
                  <p className="m-0">
                    <b>Akhila Bharata Gudigara Samaja</b> <br />
                    Sree Ramachandra Devasthana, Jog Road, Sagar 577401
                  </p>
                </li>
                <li className="mb-1">
                  <i className="fab fa-whatsapp text-color-primary" />
                  <p className="m-0">
                    <a href="tel:9448014176">+91 94480 14176</a>
                  </p>
                </li>
                <li className="mb-1">
                  <i className="far fa-envelope text-color-primary" />
                  <p className="m-0">
                    <a
                      href="mailto:
admin@gudigars.com"
                    >
                      admin@gudigars.com
                    </a>
                  </p>
                </li>
                <li className="mb-1">
                  <i className="far fa-envelope text-color-primary" />
                  <p className="m-0">
                    <a
                      href="mailto:
gudigarsworld@gmail.com"
                    >
                      gudigarsworld@gmail.com
                    </a>
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-6 col-lg-2">
            <h5 className="text-3 mb-3">FOLLOW US</h5>
            <ul className="social-icons">
              <li className="social-icons-facebook">
                <Link
                  to={{
                    pathname:
                      "https://www.youtube.com/channel/UCSKUoh8AfnRLQw4v9JNiFfw",
                  }}
                  target="_blank"
                  title="Facebook"
                >
                  <i className="fab fa-youtube"></i>
                </Link>
              </li>
              <li className="social-icons-facebook">
                <Link
                  to={{
                    pathname: "https://www.facebook.com/groups/gudigarlook",
                  }}
                  target="_blank"
                  title="Facebook"
                >
                  <i className="fab fa-facebook-f" />
                </Link>
              </li>
              <li className="social-icons-twitter">
                <Link
                  to={{
                    pathname: "https://twitter.com/gudigars",
                  }}
                  target="_blank"
                  title="Twitter"
                >
                  <i className="fab fa-twitter" />
                </Link>
              </li>
              <li className="social-icons-instagram">
                <Link
                  to={{
                    pathname: "https://www.instagram.com/gudigars",
                  }}
                  target="_blank"
                  title="instagram"
                >
                  <i className="fab fa-instagram" />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer-copyright">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 d-flex align-items-center justify-content-center justify-content-lg-start mb-2 mb-lg-0">
              <a href="/" className="logo pr-0 pr-lg-3">
                <img
                  alt="Porto Website Template"
                  src="img/logo-footer.png"
                  height={60}
                />
              </a>
            </div>
            <div className="col-lg-5 d-flex align-items-center justify-content-center justify-content-lg-start mb-4 mb-lg-0">
              <p>© Copyright 2020. All Rights Reserved.</p>
            </div>
            <div className="col-lg-3 d-flex align-items-center justify-content-center justify-content-lg-end">
              <nav id="sub-menu">
                <ul>
                  <li>
                    <i className="fas fa-angle-right" />
                    <Link to="/contact" className="ml-1 text-decoration-none">
                      {" "}
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </footer>
    </div>
    </>
  );
};

export default Footer;
