import React from "react";
import { Link } from "react-router-dom";
// import { useSelector, useDispatch } from "react-redux";
// import { logout } from "../../src/actions/auth";
import Navbar from './navbar/navbar'

export default function Header() {
  // const auth = useSelector(state => state.auth);
  // const dispatch = useDispatch()
  // const user = {
  //   role: ["default", "Gudigar"]
  // }
  // const menu = [
  //   {
  //     menuName: 'Home',
  //     authRequired: false,
  //     authShowOn: true,
  //     role:"default",
  //     link: "/"
  //   },
  //   {
  //     menuName: 'About ABGS',
  //     authRequired: false,
  //     authShowOn: true,
  //     role:"default",
  //     link: "/about"
  //   },
  //   {
  //     menuName: 'History',
  //     authRequired: true,
  //     authShowOn: true,
  //     role:"Gudigar",
  //     link: "/historyofgudigars",
  //     subMenu: [
  //       {
  //         menuName: 'History of Gudigars',
  //         authRequired: true,
  //         authShowOn: true,
  //         role:"Gudigar",
  //         link: "/historyofgudigars",
  //       },
  //       {
  //         menuName: 'Gudigar Arts',
  //         authRequired: true,
  //         authShowOn: true,
  //         role:"Gudigar",
  //         link: "/gudigararts",
  //       },
  //       {
  //         menuName: 'Recognitions',
  //         authRequired: true,
  //         authShowOn: true,
  //         role:"Gudigar",
  //         link: "/recognitions",
  //       },
  //       {
  //         menuName: 'Social cause',
  //         authRequired: true,
  //         authShowOn: true,
  //         role:"Gudigar",
  //         link: "/socialcause",
  //       },
  //       {
  //         menuName: 'Acknowledgements',
  //         authRequired: true,
  //         authShowOn: true,
  //         role:"Gudigar",
  //         link: "/acknowledgements",
  //       },
  //     ]
  //   },
  //   {
  //     menuName: 'CONTACT US',
  //     authRequired: false,
  //     role:"default",
  //     link: "/contact"
  //   },
  //   {
  //     menuName: (auth.isAuthenticated && auth.user)?auth.user.username:'My Account',
  //     // menuName: 'My Account',
  //     authRequired: true,
  //     authShowOn: true,
  //     role:"default",
  //     link: "#",
  //     subMenu: [
  //       {
  //         menuName: <i className="icon-user icons"> Profile</i> ,
  //         authRequired: true,
  //         authShowOn: true,
  //         role:"default",
  //         link: "/profile"
  //       },
  //       {
  //         menuName: <i className="icon-user icons"> Admin</i> ,
  //         authRequired: true,
  //         authShowOn: true,
  //         role:"Admin",
  //         link: "/"
  //       },
  //       {
  //         menuName: <i className="icon-logout icons"> Logout</i> ,
  //         authRequired: true,
  //         authShowOn: true,
  //         role:"default",
  //         link: "/",
  //         onClick: () => logout()
  //       }
  //     ]
  //   },
  //   {
  //     menuName: 'REGISTER',
  //     authRequired: true,
  //     authShowOn: false,
  //     role:"default",
  //     link: "/register"
  //   },
  //   {
  //     menuName: 'LOGIN',
  //     authRequired: true,
  //     authShowOn: false,
  //     role:"default",
  //     link: "/login"
  //   }
  // ]
  // const logout = () => {
  //   dispatch({
  //     type: "CLEAR_PROFILE",
  //   });
  //   dispatch({
  //     type: "LOGOUT",
  //   });
  // };

  // const showMenuItem = (item) => {
  //   // console.log(item)
  //   if (!item.authRequired) return true
  //   if (item.authRequired && auth.isAuthenticated && item.authShowOn) {
  //     if (item.role){
  //       if (user.role.includes(item.role)){return true}
  //       else{return false}
  //     }
  //     return true
  //   }
  //   if (item.authRequired && !auth.isAuthenticated && !item.authShowOn) return true
  //   return false
  // }

  return (
    <>
    <div className="container">
    <header
      id="header"
      data-plugin-options="{'stickyEnabled': true, 'stickyEnableOnBoxed': true, 'stickyEnableOnMobile': true, 'stickyStartAt': 164, 'stickySetTop': '-164px', 'stickyChangeLogo': false}" >
      <div className="header-body border-0">
        <div className="header-top header-top-default border-bottom-0 ">
          <div className="container">
            <div className="header-row ">
              <div className="header-column justify-content-start">
                <div className="header-row">
                  <nav className="header-nav-top">
                    <ul className="nav nav-pills text-uppercase text-2">
                      <li className="nav-item nav-item-anim-icon">
                        <Link to="/about">About Us</Link>
                      </li>
                      <li className="nav-item nav-item-anim-icon">
                        <Link
                          className="nav-link text-light opacity-7 pr-0"
                          to="/contact"
                        >
                          <i className="fas fa-angle-right" /> Contact Us
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
              <div className="header-column justify-content-end">
                <div className="header-row">
                  <ul className="header-social-icons social-icons d-none d-sm-block social-icons-clean social-icons-icon-light">
                    <li className="social-icons-facebook">
                      <Link
                        to={{
                          pathname:
                            "https://www.facebook.com/groups/gudigarlook",
                        }}
                        target="_blank"
                        title="Facebook"
                      >
                        <i className="fab fa-facebook-f" />
                      </Link>
                    </li>
                    <li className="social-icons-facebook">
                      <Link
                        to={{
                          pathname:
                            "https://www.youtube.com/channel/UCSKUoh8AfnRLQw4v9JNiFfw",
                        }}
                        target="_blank"
                        title="Youtube"
                      >
                        <i className="fab fa-youtube"></i>
                      </Link>
                    </li>

                    <li className="social-icons-twitter">
                      <Link
                        to={{
                          pathname: "https://twitter.com/gudigars",
                        }}
                        target="_blank"
                        title="Twitter"
                      >
                        <i className="fab fa-twitter" />
                      </Link>
                    </li>
                    <li className="social-icons-instagram">
                      <Link
                        to={{
                          pathname: "https://www.instagram.com/gudigars",
                        }}
                        target="_blank"
                        title="instagram"
                      >
                        <i className="fab fa-instagram" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="header-container container custom-header">
          <div className="header-row">
            <div className=" header-column justify-content-start w-50 order-md-1 d-none d-md-flex">
              <div className="header-row ">
                <ul className="header-extra-info">
                  <li className="m-0">
                    <div className="feature-box feature-box-style-2 align-items-center">
                      <div className="feature-box-icon">
                        <i className="fas fa-map-marker-alt text-7 p-relative" />
                      </div>
                      <div className="feature-box-info">
                        <p className="pb-0 font-weight-semibold line-height-5 text-2">
                          Akhila Bharata Gudigara Samaja
                          <br />
                          Sree Ramachandra Devasthana, <br /> Jog Road, Sagar
                          577401
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className=" header-column justify-content-start justify-content-md-center order-1 order-md-2">
              <div className="header-row">
                <div className="header-logo">
                  <Link to="/">
                    <img alt="gudigars_logo" src="/img/Gudigars.comlogo7.png" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="header-column justify-content-end w-50 order-2 order-md-3">
              <div className="header-row">
                <ul className="header-extra-info">
                  <li className="m-0">
                    <div className="feature-box reverse-allres feature-box-style-2 align-items-center">
                      <div className="feature-box-icon">
                        <i
                          className="fab fa-whatsapp text-7 p-relative"
                          style={{ top: "-2px" }}
                        />
                      </div>
                      <div className="feature-box-info">
                        <p className="pb-0 font-weight-semibold line-height-5 text-2">
                          admin@gudigars.com
                          <br />
                          gudigarsworld@gmail.com <br /> +91 94480 14176
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <Navbar/>
    </div>
    </>
  );
};
