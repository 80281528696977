import React from "react";
import spinner from "../loader.gif";

export default function Spinner() {
  return (
    <>
      <img src={spinner} alt="Loading..." style={style.spinner} />
    </>
  );
}

const style = {
  spinner: {
    position: "absolute",
    height: "40px",
    width: "40px",
    top: "50%",
    left: "50%",
    marginLeft: "-50px",
    marginTop: "-50px",
  },
};
