import React from 'react'
import {useSelector} from 'react-redux'
import Title from './title'
import ImageObject from './imageObject'
import Caption from './caption'
import ShowHistoryPages from './showHistoryPages'
import SectionBlock from "../SectionBlock";

export default function HistoryComponent(props) {
    const auth = useSelector(state => state.auth);
    const pages = [
        {
            1:<div><Title>Gudigar’s Cooperative Societies and other Production Centers (Workshops)</Title>
                    <p>Our ancestors had created a kind of cooperative movement in Karnataka as early as 1940s, forming Gudigar Societies in towns with sizeable Gudigar population like Sorab, Sagar, Sirsi, Kumta and Honnavar in 1940s. This was a great initiative that provided help to the artisans. As there was a great demand for sandalwood artifacts by British personnel leaving India after Independence, these Cooperative thrived well. In the case of Sorab & Sagar Societies, the Artists were paid 7 Annas of a Rupee as advance for their product and the balance 9 Annas would be paid after the item were sold. With this arrangement they were not required to go to other private buyers who would bargain and perhaps pay less. </p>
                    <p>Some of our enterprising persons had opened their own shops and workshops in places like Sorab, Sagar, Sirsi , Kumta, Bengaluru, Belgaum , Yellapur etc. during 1940/1950s, which are still active. An enterprising person from Sorab, Sri Alekal Subbarao, a Konkani Saraswat had opened his workshop in Sorab by employing our young Gudigars in 1940s parallel to Gudigar Society. He was marketing his products directly at Mumbai clients. He would bring from Mumbai on his return trip art pieces in ivory, porcelain etc. produced in Japan and China for giving it to our young Artists as samples. On account of great demand for sandalwood and ivory products by Britishers who were departing from India after 1947, his business had flourished during that time. This philanthropic person had even helped some of our people working with him in Sorab to construct pukka houses. He would give them loan and utilize money saved by them in this pursuit. Those houses are still in existence and are being used by those family members.</p>
                <div className="all-images-in-a-row">
                <ImageObject src="/img/history/image036.jpg"></ImageObject>
                </div>
                    <Caption>Figs: Paper cutters, Key chains and Book Marks</Caption>
                    <p>A picture of a sandalwood bracket, a temple model and a casket in Victoria & Albert museum collection are shown below. The artists are unknown.</p>
                <div className="all-images-in-a-row">
                <ImageObject src="/img/history/image038.jpg"></ImageObject>
                <ImageObject src="/img/history/image039.jpg"></ImageObject>
                <ImageObject src="/img/history/image040.jpg"></ImageObject>
                </div>
                    <p>It is difficult to catalogue all the accomplishments of Gudigars in a single document. I have tried to highlight some of the key efforts here. Any omission is unintentional. The soft copies of Gudigar magazine ‘Sugandhavani’ to be shared in this website will provide the readers of the with several useful articles including life sketches of many Gudigar artists. If anyone feels that a relevant artist or his work is left out in this, please get me the data and I will update it. </p>
                </div>,
            2:<div><Title>Temples and cultural activities: </Title> 
                    <p>Gudigars are devout people. In as early as 1930s, Gudigars of the Vasishta Gotra constructed Ravalnath temple at Honnavar. Regular Puja is conducted there. The annual Dasara Puja is very special and is conducted in a grand scale when all their relatives congregate at that occasion. Now this temple is being rebuilt with a grand form and in new design. In 1945, a residential building was converted, and a Rama temple was created at Sorab. It has been rebuilt a few years ago with proper structure. Similarly, a Rama temple has been built at Sagar in 1988. There is a Ganesh temple at Sirsi complex.  All these temples have been centers of religious and cultural activities. In several towns and cities, Gudigars keep organizing annual Sri Satyanarayana pooja and use this occasion for meetings and cultural activities. Gudigars also worship forest goddesses Maneer Vana devata near Kumta and celebrate with grand feast.  Likewise, Kaan Habba and Kamana Habba are celebrated in a grand scale in Sorab. During Kamana habba Gudigars used to enact street dramas based on Hindu Mythology.</p>
                    <Title>Akhila Bharat Gudigar Samaj and other internal governing bodies: </Title>
                    <p>Akhila Bharat Gudigar Samaj (ABGS) was formed in the 60’s under the presidentship of S Devappa. He had a big role in bringing Gudigars from different parts together under the roof of ABGS. A historic convention was held at Sorab in 1967. Narahari Gudigar of Yallapaur was the vice president and Narasimha S Shetty Mumbai was the secretary. Later Devappa’s son S D Puttaswamy led the community as a president for many years. ABGS continues to work towards the upliftment of Gudigars. There have been local bodies in places with larger Gudigar population like Mumbai, Bangalore, Sirsi, Kumta, Sagar, Shivamogga and Sorab. All these have been involved in progressive activities. The ladies also have prominent cooperative bodies in different towns. Sougandhika Mahila Samaj, founded by Yamuna Krishnappa and others in 1990 has celebrated silver jubilee few years ago. The different Mahila mandals communicate with each other and organize common meetings some time.  There have also been sports meets organized within the community.</p>
                    <Title>Credit Cooperative society</Title> 
                    <p>The Shrigandha credit cooperative society, started in 1993 with an initial capital of Rs. 1,35,900 has grown slowly, but steadily and has about Rs. 1 crore business and about 800 members. </p>
                    </div>,
            3:<div>
                    <Title>Gudigars in education related fields</Title> 
                    <p>While the Gudigars have mostly been famous for the work in art, it this small community there are many who have made a mark for themselves in other fields based on formal education. The North Canara district was in Bombay presidency, and schools started earlier than Sagar, Sorab and the Gudigars in the coastal district had an advantage of learning and getting into formal jobs. The first person in the community to serve in a Government job was Timmappa G Bandodkar. He worked as a clerk around 1885-1925. Around the same time, Chidambar Chitrak of Siddapur was teaching in a school. One of Timmappa’s sons, Raghavendra Bandodkar, has the reputation of being the first gazetted officer in the community. Many Gudigar students of Honnavar’s St Thomas high School finished their matriculations and moved to Mumbai for jobs. Bhikaji Krishnaji Gudiagr, who went to Mumbai in 1920, is called ‘Columbus’. After that, many Gudigars like H R Shetty, Achyut Shetty, Shankar Kurdekar, N S Shetty, Umesh Shetty, Gopal Bandodkar, Sheshagiri Vinekar, Suresh Vinekar, Babayya Kurdekar, Ramadas Anant Shetty, Venkatrai Shetty and Madhukar Shetty moved to Mumbai. Many worked in Indian railways. Obviously, many of their children also continued to work in Mumbai. Dinakar Manjunath Shetty was in Customs. Achyut Shetty was in Thomas cook, Anandu Shetty was an art teacher and Sheshagiri Vinekar was in Glaxo Co. etc. All their children naturally are well settled in Mumbai.   Gangadhar M Shetty, Manohar Ganapati Vinekar worked in Karnataka state forest department as officers. H R Shetty had started the first Gudigar magazine in Mumbai in 1958 that did not run for long.</p>
                <div className="all-images-in-a-row">
                <ImageObject src="/img/history/image150.jpg"></ImageObject>
                </div>
                    <Caption>Fig: Cover page of the first Gudigar magazine started by H R Shetty</Caption>
                    <p>Sir J. J. School of Arts had a major role in shaping many Gudigar’s careers. Many Gudigars studied here drawing, carving and clay modeling courses. B N Ramachandrappa, Raghuveer Set, Shankat Shet, Ganapati Bandodkar, Venkatagiri Chitrak, Somanna Shet, Samba Shet, Yashavant Shet, Balakrishna Gudigar, Subraya Shet, Krishnayya Gudigar, Anandu Shetty, D M Shetty, Sarvottam Shtty, Umesh Shetty, Jayappa Chelkar, K N Chelkar, Anandu Chitrakar, Madhukar Shetty, Dayanand Shetty, Shreekant Shetty, S Najundayya, Anup Chitrak , his wife Pushpa and many others studied at Sir J. J. School of Arts (this list may not be complete).</p>
                    <p>Another institute that shaped many Gudigar’s career is the Jayachamarajendra Technical Institute of Mysore. M Ganapatappa, Mysore Venkappa and S Bangarappa were teaching here at different times. B N Ramachandrappa, Jade Manjunathappa and Malagi Giriyappa had studied here. </p>
                    <p>First engineer from the community was Parashuram Ganapatappa Maroor. He did his diploma in 1939 and worked in electricity board. First person to get a degree was K Krishnappa. He was known as BSc Krishnappa. He worked in Many Government departments and was a BDO. He was a multifaceted person with interest in performing arts too. First person to complete engineering was G Vitthalarao. He got his degree in 1959. First doctor was M C Krishnamurthy and his younger brother M C Venkatesh was first postgraduate engineer. First postgraduate was S L Muralidhar, who was the chief editor of the Gudigar magazine ‘Sugandhavani’. First person to get a Ph D is Dr Anil N Shetty. He got is Ph D in physics from Kent State University in the US in 1982. He is settled in the US. Madhav B Kurdekar was the first CA in our community. He served in senior positions in GSK.  Harihar Chelkar of Kumta completed his BSc in first class in 1960 and worked in reserve bank of India. Ganapati K Kurdekar of Ankola had served in Karnataka secretariat in gazetted posts. In the 60’s, S G Anandappa was the first sub registrar from the community. His cousin S G Ranganathappa was an officer in Mysore state electricity board. Puttaswamy C Gudigar, who owns Shilpaloka, after his master’s in archeology, worked in National Institute of Oceanography as a marine archeologist. Dr Shantakumari was the first lady doctor from the community, and she worked as a medical officer in Government hospitals. Captain Krishnamurthy, an Engineer, has worked in Navy in submarines. </p>
                    <p>The early educated people in Malnad region also excelled in carving. S G Ranganathappa was a master craftsman despite being an officer in Electricity Department. M C Venkatesh, S L Muralidhar and other also knew wood carving. Bileggodu Narayan was working at a photographer in University of Agricultural Sciences and took up sandalwood carving after his retirement. He is one of the finest craftsmen we have seen. This wasn’t so in the coastal regions. We should be happy that today there are few doctors among Gudigars and many engineers. There are few PhDs and some of the brilliant people from the community are working in countries like the US, UK, Austria, Australia and the gulf. Many of the younger generation also have pursued fine art degrees at Karnataka Chitrakala Parishat and have moved to art-based jobs.</p>
                <div className="all-images-in-a-row">
                <ImageObject src="/img/history/image151.jpg"></ImageObject>
                </div>
                    <Caption>Fig: A 2 feet tall teak wood Lakshminarayan carved by M C Venkatesha as a hobby</Caption>   
                <div className="all-images-in-a-row">
                <ImageObject src="/img/history/image152.jpg"></ImageObject>
                </div>
                    <Caption>Fig. S G Ranganathappa with some of his works</Caption>
                    <p>One of the biggest achievements in academics in our small community has been by T L Satyaprakash. He got second rank in IAS in 2002 and is currently working as DG & special secretary Skill development and industrial training and employment department, Haryana.  This is indeed a matter of great pride to the small, unique community like Gudigars. His younger brother Praveen is Police Officer and is presently posted at Bengaluru.</p>
                    <p>While it is a dilemma that with more and more Gudigar children doing well in academics and choosing lucrative professions, the art is taking a back seat. I feel it is important to promote Gudigar art and make sure that it is not lost. This can happen only when there is more respect for the artists and better financial rewards for art. </p>
                    </div>,
        }]
    return (
        <div className="container">
            <SectionBlock name="Social cause" />
            {!auth.isAuthenticated && <>You are not Logged in yet !</>}
            {auth.isAuthenticated && 
            <>
                <ShowHistoryPages pages={pages}/>
           </>
            }
        </div>
    )
}
