import React from 'react'
import {useSelector} from 'react-redux'
import Title from './title'
import ImageObject from './imageObject'
import Caption from './caption'
import ShowHistoryPages from './showHistoryPages'
import SectionBlock from "../SectionBlock";

export default function HistoryComponent(props) {
    const auth = useSelector(state => state.auth);
    const pages = [
        {
            1:<div><Title>National awards: </Title>
                <p>Post-independence, the National awards for handicrafts were instituted and three artists so far have received it. State governments of Karnataka and Maharashtra have recognized notable works and have given awards to artists. Three artists have received the national awards so far. </p>
                1. Vitthal Ramachandra Shetty of Kumta in 1969 for ‘Vishwakarma’, three-dimensional panel in sandalwood.
                2. Vitthal Manjunath Shetty of Honnavar in 1972 for “Samudra manthana” in sandalwood.
                3. Ashok C Gudigar in 1992 for ‘Venugopala’ made in Shivani wood. 
                <div className="all-images-in-a-row">
                <ImageObject src="/img/history/image022.jpg"></ImageObject>
                </div>
                <Caption>Fig: Vishwakarma, national award-winning Sandalwood article </Caption>
                <div className="all-images-in-a-row">
                <ImageObject src="/img/history/image023.jpg"></ImageObject>
                <ImageObject src="/img/history/image023-1.jpg"></ImageObject>
                <ImageObject src="/img/history/image023-2.jpg"></ImageObject>
                </div>
                <Caption>Fig: Vittal Ramachandra Shetty, Kumta at work</Caption>
                <div className="all-images-in-a-row">
                <ImageObject src="/img/history/image024.jpg"></ImageObject>
                <ImageObject src="/img/history/image025.jpg"></ImageObject>
                <ImageObject src="/img/history/image026.jpg"></ImageObject>
                </div>
                <Caption>Figs:   Vitthal Manjunath Shetty, Honnavar receiving the national award and his works</Caption>
                <div className="all-images-in-a-row">
                <ImageObject src="/img/history/image027.jpg"></ImageObject>
                <ImageObject src="/img/history/image028.jpg"></ImageObject>
                </div>
                <Caption>Fig: Venugopala, National award-winning carving of Ashok Gudigar and Ashok Gudigar with his stone creation</Caption>
                <div className="all-images-in-a-row">
                <ImageObject src="/img/history/image028-1.jpg"></ImageObject>
                </div>
                <Caption>Fig: Ashok Gudigar receiving Smt. Kamaladevi Chattopadhyaya Vishwakarma award from Smt. Kamaladevi</Caption>
                <p>Vitthal Ramachandra Shetty evidently is one of the most talented and celebrated Gudigar craftsman and has been conferred with many other awards including “Jakanachari award” in 1998. He also had international projects where he created Buddhas for Japanese Government. Ashok G Gudigar is a young artist who has received many laurels along with the National award. He has also been recipient of Vishwakarma, State award and Shilpakala academy awards. Jade Manjunathappa and D G Ramachandrappa of Sagar have received President’s merit certificates.</p>
                </div>,
            2:<div><Title>State awards:</Title> 
                <p>Karnataka State Handicrafts Development Corporation has been rewarding artists in different streams. Since its inception, 21 artists from our community have received Karnataka state awards for handicrafts and 15 have been awarded with merit certificates. These awards certainly help the artists to get recognition and exposure while helping them financially. Following are the lists of State awardees and recipients of merit certificates. It is unfortunate that there have been no state awards for the past eight years.</p>
                <table>
                    <tr><td>Year</td><td>Name</td><td>Place</td><td>Category</td></tr>
                    <tr><td>1969</td><td>	Madhav Ganapati Shet	</td><td>Kumta	</td><td>Sandalwood carving</td></tr>
                    <tr><td>1970</td><td>	Vitthal Manjunath Shetty	</td><td>Honnavar	</td><td>Sandalwood carving</td></tr>
                    <tr><td>1971</td><td>	Vitthal Ramachandra Shetty	</td><td>Kumta	</td><td>Sandalwood carving</td></tr>
                    <tr><td>1971</td><td>	S G Nagaraj	</td><td>Bangalore	</td><td>Wood carving</td></tr>
                    <tr><td>1972</td><td>	D G Ramachandrappa	</td><td>Bangalore	</td><td>Sandalwood carving</td></tr>
                    <tr><td>1985</td><td>	Samba Anant Shet	</td><td>Kumta	</td><td>Sandalwood carving</td></tr>
                    <tr><td>1987</td><td>	J C Gangadhar	</td><td>Sorab	</td><td>Sandalwood carving</td></tr>
                    <tr><td>1989</td><td>	Ashok C Gudigar	</td><td>Bidadi	</td><td>Sandalwood carving</td></tr>
                    <tr><td>1990</td><td>	Jade Manjunathappa	</td><td>Sagar	</td><td>Sandalwood carving</td></tr>
                    <tr><td>1991</td><td>	Devidas Datta Shet	</td><td>Kumta	</td><td>Sandalwood carving</td></tr>
                    <tr><td>　	</td><td>K G Shantappa	</td><td>Sagar	</td><td>Sandalwood carving</td></tr>
                    <tr><td>　	</td><td>Yashwant Irayya Shet	</td><td>Dharwar	</td><td>Sandalwood carving</td></tr>
                    <tr><td>1995</td><td>	Usha Nagaraj	</td><td>Bangalore	</td><td>Traditional painting</td></tr>
                    <tr><td>2002</td><td>	Raghavendra Bilgi	</td><td>Bidadi	</td><td>Stone carving</td></tr>
                    <tr><td>　	</td><td>Ganesh Ramanna Maroor	</td><td>Belagavi	</td><td>Wood carving</td></tr>
                    <tr><td>2003</td><td>	B G Manjunath	</td><td>Sagar	</td><td>Sandalwood carving</td></tr>
                    <tr><td>2008</td><td>	J S Vivekananda	</td><td>Shivamogga	</td><td>Sandalwood carving</td></tr>
                    <tr><td>2009</td><td>	Trivikram Gudigar	</td><td>Shikaripur	</td><td>Sandalwood carving</td></tr>
                    <tr><td>　	</td><td>Suresh C Gudigar	</td><td>Bidadi	</td><td>Wood carving</td></tr>
                    <tr><td>2010</td><td>	Arun Narayan Shetty	</td><td>Honnavar	</td><td>Sandalwood carving</td></tr>
                    <tr><td>2011</td><td>	B Shivakumar	</td><td>Sagar	</td><td>Sandalwood carving</td></tr>
                    </table>
                </div>,
            3:<div><Title>State merit certificate</Title> 
                <table className="centre">
                    <tr><td>Year</td><td>Name</td><td>Place</td><td>Category</td></tr>
                    <tr><td>1970</td><td>Subbaray R Shet</td><td>Kumta</td><td>Sandalwood carving</td></tr>
                    <tr><td>1971</td><td>Ganapati Manjunath Shetty</td><td>Honnavar</td><td>Sandalwood carving</td></tr>
                    <tr><td>1983</td><td>Devidas Datta Shet</td><td>Kumta</td><td>Sandalwood carving</td></tr>
                    <tr><td>1985</td><td>Ashok C Gudigar</td><td>Bidadi</td><td>Sandalwood carving</td></tr>
                    <tr><td>1987</td><td>G R Gajanana</td><td>Sagar</td><td>Sandalwood carving</td></tr>
                    <tr><td>　</td><td>Usha Nagaraj</td><td>Bangalore</td><td>Painting</td></tr>
                    <tr><td>1988</td><td>Suresh C Gudigar</td><td>Bidadi</td><td>Wood carving</td></tr>
                    <tr><td>1992</td><td>M J Narayanappa</td><td>Sagar</td><td>Sandalwood carving</td></tr>
                    <tr><td>1994</td><td>D Jagadish</td><td>Sagar</td><td>Wood carving</td></tr>
                    <tr><td>2002</td><td>Shyam Gugigar</td><td>Mysore</td><td>Sandalwood carving</td></tr>
                    <tr><td>2003</td><td>Arun Narayan Shetty</td><td>Honnavar</td><td>Sandalwood carving</td></tr>
                    <tr><td>　</td><td>J S Vivekananda</td><td>Shivamogga</td><td>Sandalwood carving</td></tr>
                    <tr><td>2004</td><td>B Bangarappa</td><td>Sagar</td><td>Sandalwood carving</td></tr>
                    <tr><td>2008</td><td>Seeta Rajendra Gudigar</td><td>Kumta</td><td>Sandalwood garlands</td></tr>
                    <tr><td>2011</td><td>Asha A Bilgi</td><td>Bangalore</td><td>Sandalwood garlands</td></tr>
                </table>
                <p>Karjagi Shantappa (1916-1997), was one of the born Artists of our community.  Even his elder brothers, Karjagi Ganapathappa and Karjagi Chowdappa were excellent workers. Karjagi Ganapatappa was an expert in preparing Palanquins. The Palanquin donated by him to Sri Rama Temple at Sorab is a testimony for his mastery in woodwork.  Shantappa was nephew of Artist Mudugodu Hiranyappa and had grownup under his shadow.  His younger days were spent in Veeranapura near Mudugodu in the midst of forest watching the nature. He had acquired skill in all mediums like wood, sandal wood, clay, stone and even in shola pith craft. He developed the technique of preparing flowers using thin sandalwood laminates. It gave a new opening to our ladies to prepare garlands using sandalwood laminates or shavings. In his mid-age he was appointed by the Karnataka Department of Industries and Commerce to train youngsters for producing pith hats used by Police. A School, therefore, was opened at Sorab perhaps in 1960s. Students were also taught other pith crafts. The School got closed after his retirement. He had concentrated on stonework at his later years. The stone statues installed in Sri Rama Temple at Sagar were prepared by him. Similarly, the stone statue of Durga installed at Marikamba Temple at Sagar was prepared by him. His first stone Ganesha has been installed at Ravindra Nagar in Shimoga. About 5 feet tall Anjaneya has been installed at Hagaribommanahalli. He had developed a lathe machine at Sorab operated manually for preparing items required for preparing ‘Mani Pushpa Haras”. During the visit of Pandit Jawaharlal Nehru to Sagar, the then PM in 1960s, a garland made by Shantappa named “Panchasheela Hara” was presented to him. The garland had both sandalwood beads and ivory engraved small plates.</p>
                <div className="all-images-in-a-row">
                <ImageObject src="/img/history/image029.jpg"></ImageObject>
                <ImageObject src="/img/history/image030.jpg"></ImageObject>
                </div>
                <Caption>Fig: Sandalwood Devi made by K G Shantappa.  Fig: Artist with his work</Caption> 
                <div className="all-images-in-a-row">
                <ImageObject src="/img/history/image031.jpg"></ImageObject>
                </div>
                    <Caption>Fig:    A stone statue being carved by K. G. Shantappa</Caption>
                </div>,
            4:<div><Title>Rajyotsava awards</Title> 
                    <p>These are annually given to achievers in different fields aby the state government. So far, three people have received these awards. Shrikant C Shetty got it in 2002 for fine arts, Devidas Datta Shet in 2005 for sculpture and Puttaswamy C Gudigar got it in 2012 for service outside the state.</p>
                <div className="all-images-in-a-row">
                <ImageObject src="/img/history/image031-1.jpg"></ImageObject>
                </div>
                    <Caption>Fig: Srikant Shetty receiving Rajyotsava award</Caption>
                <div className="all-images-in-a-row">
                <ImageObject src="/img/history/image031-2.jpg"></ImageObject>
                </div>
                    <Caption>Fig: Devidas D Shet receiving Rajyotsava award</Caption>
                <div className="all-images-in-a-row">
                <ImageObject src="/img/history/image031-3.jpg"></ImageObject>
                </div>
                    <Caption>Fig: Puttaswamy C Gudigar receiving Rajyotsava award</Caption>
                </div>,
            5:<div><Title>Shilpakala academy awards:</Title> 
                    <p>Karnataka Shilpakala Academy has been arranging exhibitions of selected artists and recognizing the. Since 1995, eight Gudigars have received these awards. </p>
                    <table>
                    <tr><td>Year	</td><td>Name</td><td>Place</td></tr>
                    <tr><td>1995	</td><td>Vitthal Chidambar Chitrak</td><td>Siddapur</td></tr>
                    <tr><td>1996	</td><td>Samba Anant Shet</td><td>Kumta</td></tr>
                    <tr><td>1999	</td><td>Datta Irayya Shet</td><td>Kumta</td></tr>
                    <tr><td>1999	</td><td>J Manjunathappa</td><td>Sagar</td></tr>
                    <tr><td>2002	</td><td>Yashavant Irayya Shet</td><td>Dharwad</td></tr>
                    <tr><td>2004	</td><td>Devidas D Shet</td><td>Kumta</td></tr>
                    <tr><td>2006	</td><td>Ganapati Manjunath Shetty</td><td>Honnavar</td></tr>
                    <tr><td>2018	</td><td>Ashok C Gudigar</td><td>Bidadi</td></tr>
                    <tr><td>2019	</td><td>S G Nagaraj</td><td>Bangalore</td></tr>
                    </table>
                <div className="all-images-in-a-row">
                <ImageObject src="/img/history/image032.jpg"></ImageObject>
                </div>
                    <Caption>Fig: Datta I Shet Kumta teaching art skills to his son Devidas D Shet</Caption>
                <div className="all-images-in-a-row">
                <ImageObject src="/img/history/image033.jpg"></ImageObject>
                <ImageObject src="/img/history/image034.jpg"></ImageObject>
                <ImageObject src="/img/history/image035.jpg"></ImageObject>
                </div>
                    <Caption>Figs: Samba Anant Shet with Jawaharlal Nehru and Dr Rajendra Prasad, Samba and Somanna Shet brothers</Caption>
                    <p>It is nice to see the state recognizing Gudigar’s talent. I hope several other artists get rewarded in the coming days. Certainly, there are more artists who deserve recognition. That will also inspire more youngsters to get into fine arts.</p>
                </div>,
        }]
    return (
        <div className="container">
            <SectionBlock name="Recognitions" />
            {!auth.isAuthenticated && <>You are not Logged in yet !</>}
            {auth.isAuthenticated && 
            <>
                <ShowHistoryPages pages={pages}/>
           </>
            }
        </div>
    )
}
